<div class="section-container" cdkDropList (cdkDropListDropped)="dropSection($event)">
  <ng-container *ngFor="let section of sections; let sectionIndex = index; let last = last; trackBy: trackByFn">
    <div class="section-card" class="col-12 pr-0 bg-white" cdkDrag cdkDragBoundary=".template-container"
      [cdkDragDisabled]="sections?.length < 2 || false">
      <div class="d-flex align-items-center">
        <div *ngIf="true" class="d-flex align-items-center mb-2" cdkDragHandle>
          <mat-icon class="cursor-move txt-secondary">drag_indicator</mat-icon>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1 flex-1">
          <form class="flex-1" #editNameForm="ngForm" name="editNameForm">
            <mat-form-field style="width: 100%;" class="pr-4" subscriptSizing="dynamic">
              <input matInput conpulseWebSanitizeHtml [(ngModel)]="section.title" name="{{ 'title' + sectionIndex }}"
                placeholder="Enter Section Title" (blur)="section?.title && (section.title = trim(section.title))"
                (ngModelChange)="onTitleChanges(sectionIndex)" />
            </mat-form-field>
          </form>
        </div>
        <div class="f-14 mb-3  pointer   d-flex align-items-center justify-content-center"
          [matTooltip]="'Remove Section' | i18next" (click)="removeSection(sectionIndex)">
          <mat-icon class="menu-delete-icon-btn">delete</mat-icon>
        </div>
      </div>
      <conpulse-web-customisation-dynamic-template [dynamicFormLists]="section.fields" [tabName]="tabName"
        [sectionTitle]="section.title"
        (onAddGeneralProvisions)="onAddDynamicFields($event, sectionIndex)"></conpulse-web-customisation-dynamic-template>
    </div>
    <div *ngIf="!last" class="h-24 add-section  pointer   d-flex align-items-center py-2"
      (click)="addSection(sectionIndex + 1)">
      <div class="h-2 width-100 add-content"></div>
      <div class="mat-8  pointer d-flex align-items-center justify-content-center add-icon">
        <mat-icon>add</mat-icon>
      </div>
    </div>
  </ng-container>
</div>
<button type="button" mat-stroked-button color="primary" (click)="addSection(sections?.length ? sections.length : 0)"
  class="icon-button mb-3" [ngClass]="{'mt-3': sections?.length}">
  <mat-icon class="mat-20 mr-2">add</mat-icon> {{ 'Add Section' }}
</button>
