import { Directive, ElementRef, HostListener, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[conpulseWebSanitizeHtml]',
})
export class SanitizeHtmlDirective {
  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  @HostListener('blur', ['$event'])
  onBlur(event: Event): void {
    const inputElement = event?.target as HTMLInputElement;
    if(inputElement?.value){
      const sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, inputElement.value) || '';
      this.renderer.setProperty(inputElement, 'value', sanitizedContent);
    }
  }
}
