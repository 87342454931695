@if(tabName !== 'Specific Questions') {
<div class="template-container" cdkDropList (cdkDropListDropped)="dropSection($event)">
  <ng-container *ngFor="let values of displayList; let i = index; let last = last; trackBy: trackByFn">
    <!-- [cdkDropListDisabled]="disableDragAndDrop || !isEditedOrAdded()" -->
    <!--  -->
    <div class="dynamic-card" class="col-12 px-0 bg-white" cdkDrag cdkDragBoundary=".template-container"
      [cdkDragDisabled]="displayList.length < 2 || false">
      <div class="d-flex align-items-center">
        <div *ngIf="true" class="d-flex align-items-center mb-2 mr-1" cdkDragHandle>
          <mat-icon class="cursor-move txt-secondary">drag_indicator</mat-icon>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-1 flex-1">
          <div class="d-flex align-items-center" [ngClass]="{ 'flex-1': values?.isNew || !viewList[i] }">
            <div class="w-100">
              <form class="flex-1" #editNameForm="ngForm" name="editNameForm">
                <mat-form-field style="width: 100%" class="pr-4" subscriptSizing="dynamic">
                  <input matInput conpulseWebSanitizeHtml [(ngModel)]="displayList[i].title" name="{{ 'title' + i }}"
                    (ngModelChange)="onValueChanges(i)" (blur)="values?.title && (values.title = trim(values.title))" />
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="values.isActive">
        <div class="d-flex justify-content-between pl-4">
          <div class="w-100">
            <ng-container [ngSwitch]="values?.type">
              <div class="mb-2">
                <form #editDescForm="ngForm" name="editDescForm">
                  <ng-container *ngSwitchDefault>
                    <div class="quill-height-200 ">
                      <editor  [init]="initEditor(('Please provide details on ' | i18next) + values.title)" [readOnly]="!displayList[i].title" (ngModelChange)="onValueChanges(i)" [(ngModel)]="values.commonData.text" name="{{ 'quillEditor' + i }}"> </editor>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="MSAFieldType.TEMPLATE">
                    <div>
                      <conpulse-web-file-input [onlyDocumentUpload]="false" [accept]="formatList"
                        (download)="downloadDocument($event)" [disabled]="isLoading" [fileLimit]="fileList"
                        [maxCount]="fileLimit" [multiple]="false" [document]="displayList[i].selectedDoc"
                        (docUploaded)="uploadDocument($event, i, MSAFieldType.TEMPLATE, false)" [showDocViewBtn]="true"
                        (view)="viewDocument($event)"></conpulse-web-file-input>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="MSAFieldType.UPLOAD">
                    <div class="">
                      <conpulse-web-file-input [onlyDocumentUpload]="false" [accept]="formatList"
                        (download)="downloadDocument($event)" [disabled]="isLoading" [fileLimit]="fileList"
                        [maxCount]="fileLimit" [multiple]="false" [document]="displayList[i].selectedDoc"
                        (docUploaded)="uploadDocument($event, i, MSAFieldType.UPLOAD)" [showDocViewBtn]="true"
                        (view)="viewDocument($event)"></conpulse-web-file-input>
                    </div>
                  </ng-container>
                </form>
              </div>
            </ng-container>
          </div>
          <div>
            <a [matMenuTriggerFor]="kebabMenu" class="pointer" #isMenuOpen="matMenuTrigger">
              <i class="material-icons project-option-icon">more_vert</i>
            </a>
            <mat-menu #kebabMenu="matMenu" class="sow-intro-menu">
              <button mat-menu-item (click)="onDelete(i)">{{ 'Remove' | i18next }}</button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="tabName === appendix; else otherTabsDiv">
      <div *ngIf="!last" class="h-24 add-section pointer d-flex align-items-center py-2"
        [ngClass]="viewList[i] || !values.isActive ? 'col-8' : 'col-12'" [matMenuTriggerFor]="menuList">
        <mat-menu #menuList="matMenu">
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.TEMPLATE)">{{ 'Template' | i18next }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.UPLOAD)">{{ 'Upload' | i18next }}</button>
          <button mat-menu-item (click)="addNewField(i + 1, MSAFieldType.QUILL)">{{ 'Rich Text' | i18next }}</button>
        </mat-menu>
        <div class="h-2 width-100 add"></div>
        <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </ng-container>
    <ng-template #otherTabsDiv>
      <div *ngIf="!last" class="h-24 add-section pointer d-flex align-items-center py-2"
        [ngClass]="viewList[i] || !values.isActive ? 'col-8' : 'col-12'" (click)="addNewField(i + 1)">
        <div class="h-2 width-100 add"></div>
        <div class="mat-8 pointer d-flex align-items-center justify-content-center add-icon">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div> 
} @else {
    <div cdkDropList (cdkDropListDropped)="dropSection($event)">
      @for (section of displayList; track section; let i = $index) {
        <div class="section-container section mt-3" cdkDrag cdkDragBoundary=".questionnaire-section"
          (cdkDragStarted)="sectionDragStarted($event)" [cdkDropListDisabled]="section?.length">
          <div [ngStyle]="{ 'min-height.px': sectionDragPlaceholderHeight }"
            class="drag-custom-placeholder mt-2 border-radius-xlarge" *cdkDragPlaceholder></div>
          <div class="title-container d-flex align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center">
              <div cdkDragHandle>
                <mat-icon
                  class="cursor-move txt-grey-secondary7 d-flex align-items-center f-20">drag_indicator</mat-icon>
              </div>
              <h3 class="f-16 f-w-600 l-h-28">{{ i + 1 }}. {{ section?.title }}</h3>
            </div>
            <div class="icon-groups">
              <mat-icon class="f-20 pointer border-radius-full p-1 more-actions"
                [matMenuTriggerFor]="sectionsMenu">more_vert</mat-icon>
              <mat-menu #sectionsMenu="matMenu">
                <button mat-menu-item (click)="openOnBoardingDrawer(onBoardingDrawerType.EditSpecificSection, i)">
                  <mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}
                </button>
                <button mat-menu-item class="delete-action"
                  (click)="deleteSpecificSection(section?.id,section?.title)">
                  <mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="question-container" cdkDropList (cdkDropListDropped)="dropQuestion($event,i)">
            @for (questions of section?.questions; track questions; let j = $index) {
              <div class="question-section d-flex align-items-start justify-content-between section mt-2" cdkDrag
                cdkDragBoundary=".question-container">
                <div [ngStyle]="{ 'min-height.px': questionDragPlaceholderHeight }"
                  class="drag-custom-placeholder m-t-12 border-radius-xlarge" *cdkDragPlaceholder></div>
                <div class="d-flex align-items-start w-100">
                  <div *ngIf="section?.questions?.length > 1" cdkDragHandle>
                    <mat-icon
                      class="cursor-move txt-grey-secondary7 f-20 d-flex align-items-center">drag_indicator</mat-icon>
                  </div>
                  <div class="ml-2 w-100">
                    <div class="d-flex align-items-center justify-content-between">
                      <p class="f-14 f-w-600 l-h-28 txt-primary-01">{{ j + 1 }}. {{ questions?.fieldTitle }}</p>
                      <div class="question-icon">
                        <mat-icon class="f-20 pointer border-radius-full p-1 more-actions"
                          [matMenuTriggerFor]="questionsMenu">more_vert</mat-icon>
                        <mat-menu #questionsMenu="matMenu">
                          <button mat-menu-item
                            (click)="openOnBoardingDrawer(onBoardingDrawerType.EditSpecificQuestion, i, j)">
                            <mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}
                          </button>
                          <button mat-menu-item class="delete-action" (click)="deleteSpecificQuestion(section?.id,questions?.id,questions?.fieldTitle)">
                            <mat-icon>delete</mat-icon>{{ 'Delete' | i18next }}
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                    <ng-container [ngSwitch]="questions?.fieldType">
                      <ng-container *ngSwitchCase="'upload'" [ngTemplateOutlet]="uploadRef"></ng-container>
                      <ng-container *ngSwitchCase="'template'" [ngTemplateOutlet]="templateRef"></ng-container>
                      <ng-container *ngSwitchCase="'multipleChoice'" [ngTemplateOutlet]="radioRef"></ng-container>
                      <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultRef"></ng-container>
                    </ng-container>
                  </div>
                  <ng-template #defaultRef>
                    <div class="template-editor-field mb-3 quill-bordered mt-3 w-100 quill-height-200 disabled"
                      [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                      <editor [init]="initEditor(questions.fieldTitle)"
                        [disabled]="true" name="{{ 'quillEditor' + j }}">
                      </editor>
                    </div>
                  </ng-template>
                  <ng-template #radioRef>
                    @if(questions?.allowMultipleChoice ) {
                    <div class="d-flex flex-column mt-2 mb-2 disabled">
                      @for (option of questions?.multipleChoice; track option) {
                      <mat-checkbox [disabled]="true">
                        <span>{{ option }}</span>
                      </mat-checkbox>
                      }
                    </div>
                    } @else {
                    <div class="d-flex mt-2 mb-2">
                      <mat-radio-group class="radio-button disabled">
                        @for (option of questions?.singleChoice; track option) {
                        <mat-radio-button [value]="option">{{ option }}</mat-radio-button>
                        }
                      </mat-radio-group>
                    </div>
                    }
                  </ng-template>
                  <ng-template #uploadRef>
                    <div class="upload-ref">
                      <label class="f-14 mt-3">{{ 'Document upload' | i18next }}</label>
                      <conpulse-web-file-input [onlyDocumentUpload]="false" [accept]="formatList" [disabled]="true"
                        [fileLimit]="fileList" [maxCount]="fileLimit" [multiple]="false"></conpulse-web-file-input>
                    </div>
                  </ng-template>
                  <ng-template #templateRef>
                    <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">Template Format</p>
                    <ng-container>
                      <div
                        class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between">
                        <div class="file-details w-80">
                          <p class="f-13 f-w-500 w-100 text-truncate">{{questions?.template?.documentType === 2 ?
                            questions?.template?.documentKey : questions?.template?.documentName}}</p>
                        </div>
                        <div class="file-actions">
                            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                              [matMenuTriggerFor]="actionMenu">more_vert</mat-icon>
                            <mat-menu #actionMenu="matMenu">
                              <button mat-menu-item *ngIf="isDownloadable(questions.template.documentKey)"
                                (click)="downloadDocument(questions?.template.documentKey, questions?.template?.documentName)">
                                <mat-icon>download</mat-icon> Download
                              </button>
                              <button mat-menu-item *ngIf="isViewable(questions?.template?.documentKey)"
                                (click)="viewDocument(questions?.template.documentKey)">
                                <mat-icon>visibility</mat-icon> View
                              </button>
                            </mat-menu>
                        </div>
                      </div>
                    </ng-container>
                    <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">Template Value</p>
                    <ng-container [ngTemplateOutlet]="uploadRef"></ng-container>
                  </ng-template>
                </div>
              </div>
            }
          </div>
          <button type="button" color="primary" class="mt-3" class="medium" mat-button
          (click)="openOnBoardingDrawer(onBoardingDrawerType.AddSpecificQuestion, i)">
          <mat-icon class="mat-20 pointer mr-1">add</mat-icon><span>{{ 'Add Question' | i18next }}</span>
        </button>
        </div>
      }
      <div class="my-3">
        <button type="button" color="primary" class="medium" mat-stroked-button
          (click)="openOnBoardingDrawer(onBoardingDrawerType.AddSpecificSection)">
          <mat-icon class="mat-20 pointer mr-1">add</mat-icon><span>{{ 'Add Section' | i18next }}</span>
        </button>
      </div>
    </div>
}
@if (tabName !== 'Specific Questions') {
<ng-container *ngIf="tabName === appendix; else otherTabs">
  <button [hidden]="!sectionTitle?.length" [matMenuTriggerFor]="menu" type="button" mat-stroked-button color="primary"
    class="icon-button mb-3" [ngClass]="{ 'mt-3': displayList?.length }">
    <mat-icon class="mat-20 mr-2">add</mat-icon> {{ 'Add Field' | i18next }}
    <mat-menu #menu="matMenu">
      <button mat-menu-item
        (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.TEMPLATE)">{{ 'Template' |
        i18next }}</button>
      <button mat-menu-item (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.UPLOAD)">{{
        'Upload' | i18next }}</button>
      <button mat-menu-item (click)="addNewField(displayList?.length ? displayList.length : 0, MSAFieldType.QUILL)">{{
        'Rich Text' | i18next }}</button>
    </mat-menu>
  </button>
</ng-container>
<ng-template #otherTabs>
  <div class="custom-btn">
    <button type="button" mat-stroked-button color="primary"
      (click)="addNewField(displayList?.length ? displayList.length : 0)" class="icon-button mb-3"
      [ngClass]="{ 'mt-3': displayList?.length }">
      <mat-icon class="mat-20 mr-2">add</mat-icon> {{ 'Add Field' | i18next }}
    </button>
  </div>
</ng-template>
}