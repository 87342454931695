import { Injectable } from '@angular/core';
import { UploadFileDetails } from '../interfaces/common-interfaces';
import { BaseApiService } from './base.api';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash-es';
import { NotificationService } from '../notification';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';

const uploaderApiUrls = {
  manage: 'files/manage',
  uploadUrl: 'files/upload-url',
  viewUrl: 'files/view-url',
  bulkDelete: 'files/bulk-delete',
  fileScan: 'file/scan'
};

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  constructor(private baseApi: BaseApiService, private notificationService: NotificationService, private http: HttpClient, private i18nextPipe: I18NextPipe) { }

  /**
   * Gets a new pre-signed URL for viewing a file stored in bucket (used for private ACL).
   * @param fileDetails Details of the file to view
   * @returns Object containing pre-signed URL and expiry time
   */

  /**TODO: Need to remove after folder structure implementation */

  getFileViewUrl(fileDetails: UploadFileDetails) {
    return this.baseApi.httpPut(uploaderApiUrls.manage, fileDetails);
  }

  /**
   * Gets new pre-signed URLs for upload and preview of files stored in bucket (Private/Public-Read ACLs).
   * @param fileDetails Details of the file to upload
   * @returns Object containing pre-signed URL for upload and public URL (if public-read ACL) and expiry time
   */
  /**TODO: Need to remove after folder structure implementation */
  getNewUploadUrl(fileDetails: UploadFileDetails) {
    return this.baseApi.httpPost(uploaderApiUrls.manage, fileDetails);
  }

  /**
   * Gets new pre-signed URLs for upload and preview of files stored in bucket (Private/Public-Read ACLs).
   * @param fileDetails Details of the file to upload
   * @returns Object containing pre-signed URL for upload and public URL (if public-read ACL) and expiry time
   */
  getUploadUrl(fileDetails: UploadFileDetails, refIds) {
    const params = new URLSearchParams()
    params.append('fileKey', fileDetails.fileKey)
    params.append('mimeType', fileDetails.mimeType)
    params.append('uploadType', fileDetails.uploadType.toString())
    Object.keys(refIds).map((key) => {
      if (refIds?.[key])
        params.append(`${key}`, refIds[key].toString())
    })
    return this.baseApi.httpGet(`${uploaderApiUrls.uploadUrl}?${params?.toString()}`)
  }

  /**
   * Gets a new pre-signed URL for viewing a file stored in bucket (used for private ACL).
   * @param fileDetails Details of the file to view
   * @returns Object containing pre-signed URL and expiry time
   */
  getViewUrl(fileDetails: UploadFileDetails) {
    const params = new URLSearchParams()
    params.append('fileKey', fileDetails.fileKey)
    if (fileDetails?.fileName)
      params.append('fileName', fileDetails?.fileName)
    params.append('responseType', fileDetails.responseType)
    return this.baseApi.httpGet(`${uploaderApiUrls.viewUrl}?${params.toString()}`);
  }

  /**
   * Uploads given data to an already generated pre-signed URL.
   * @param preSignedUrl Pre-signed URL already obtained from back-end service.
   * @param binaryData The data in binary format to be uploaded.
   * @param mimeType The MIME type of the data to be uploaded.
   * @returns
   */
  uploadToPresignedUrl(preSignedUrl: string, binaryData: unknown, mimeType: string) {
    const urlParams = new URLSearchParams(preSignedUrl);
    const myParam = urlParams.get('x-amz-tagging');
    return this.baseApi.httpPut(preSignedUrl, binaryData, { headers: { 'Content-Type': mimeType, "x-amz-tagging": myParam } });
  }

  /**
   * Deletes a file of specified type from the bucket.
   * @param fileDetails Details of the file to delete
   * @returns Delete Response
   */
  deleteFile(fileDetails: UploadFileDetails) {
    return this.baseApi.httpDelete(uploaderApiUrls.manage, fileDetails);
  }

  bulkDeleteFile(files) {
    return this.baseApi.httpDelete(uploaderApiUrls.bulkDelete, { files })
  }

  /**
   * Gets presigned URL and uploads data to the presigned URL
   * @param fileData File object
   * @param uploadPath Path in which file should be uploaded eg: path/subpath
   * @returns
   */
  async uploadToS3(fileData: File, uploadPath = '', refIds = {}) {
    const fileType = fileData.name.substring(fileData.name.lastIndexOf('.') + 1, fileData.name.length);
    const fileDetails = {
      mimeType: fileData.type,
      fileKey: `${uuidv4()}.${fileType}`,
      uploadType: uploadPath
    };
    const urls = get(await this.getUploadUrl(fileDetails, refIds).toPromise(), 'data', null);
    const uploadUrl: string = urls?.uploadUrl;
    if (uploadUrl) {
      try {
        await this.uploadToPresignedUrl(uploadUrl, fileData, fileDetails.mimeType).toPromise();
        return { key: urls.filePath };
      } catch (err) {
        throw err;
      }
    } else {
      throw "Couldn't get presigned URL";
    }
  }

  async openDocument(payload: UploadFileDetails, format?: 'DOCS' | 'SHEET') {
    try {
      const response = await this.getViewUrl(payload).toPromise();
      if (response?.data?.viewUrl) {
        // TODO:This would be needed when Docs should be previewed. 3rd Party microsoft viewer for DOCS and SHEET format
        // if(format === 'DOCS'){
        //   window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(response?.data?.viewUrl)}`);
        // }else if(format === 'SHEET'){
        //   window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(response?.data?.viewUrl)}`)
        // }else{
        window.open(response?.data?.viewUrl);
        // }
      }
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  scanFile(file: File): Observable<{ isInfected: boolean }> {
    const formdata = new FormData();
    formdata.append("file", file);
    return this.http.post(uploaderApiUrls.fileScan, formdata).pipe(
      map((res) => res["data"]),
      catchError(error => {
        this.notificationService.openErrorSnackBar(`${this.i18nextPipe.transform('Failed to scan file')} - ${file.name}`);
        return throwError(() => new Error(error.message));
    }));
  }
}
