import { Component, effect, inject, input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { FormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { v4 as uuidv4 } from 'uuid';
import { ConpulseModule } from '../../conpulse.module';
import { DynamicFormValidationChipModule } from '../dynamic-form-validation-chip/dynamic-form-validation-chip.module';
import { ConpulseMaterialModule } from '../../material-module/conpulse-material.module';
import { UploadDocsOrLinkModule } from '../upload-docs-or-link/upload-docs-or-link.module';
import {
  CONSTANTS,
  DefaultDrawerDetails,
  DeleteConfirmationComponent,
  noDataContent,
  NotificationService,
  OnBoardingEditDrawerType,
  RfpInformation,
  SpecificQuestions,
  UploaderService,
  UserService,
  ViewType,
} from '@conpulse-web/core';
import { MatDialog } from '@angular/material/dialog';
import { TinyMceService, UtilityMethodsService } from '../../services';
import { FileInputModule } from '../file-input/file-input.module';
import { NoContentModule } from '../no-content/no-content.module';

@Component({
  selector: 'conpulse-web-dynamic-specific-questions',
  standalone: true,
  imports: [
    CommonModule,
    ConpulseModule,
    ConpulseMaterialModule,
    DragDropModule,
    FormsModule,
    DynamicFormValidationChipModule,
    I18NextModule,
    UploadDocsOrLinkModule,
    EditorModule,
    FileInputModule,
    NoContentModule,
  ],
  templateUrl: './dynamic-specific-questions.component.html',
  styleUrl: './dynamic-specific-questions.component.scss',
})
export class DynamicSpecificQuestionsComponent implements OnInit {
  rfpData = input<RfpInformation>(undefined);
  tabNumber = input<number>(0);
  restrictEdit = input<boolean>(false);
  isProvider = input<boolean>(false);

  fileList = [];
  formatList = [];
  documentDetails = { size: 10 * 1024 * 1024, allowedFiles: '' };
  onBoardingDrawerType = OnBoardingEditDrawerType;
  displayList: SpecificQuestions[];
  initialDisplayLists: SpecificQuestions[];
  noData: noDataContent = {} as noDataContent;

  private dialog = inject(MatDialog);
  private tinyMceService = inject(TinyMceService);
  private utilityMethod = inject(UtilityMethodsService);
  private userService = inject(UserService);
  private uploaderService = inject(UploaderService);
  private notificationService = inject(NotificationService);
  private i18nextPipe = inject(I18NextPipe);

  constructor() {
    this.noData = JSON.parse(JSON.stringify(CONSTANTS.NoDataContent));
    this.noData.isActionNeed = true;
    this.noData.btnName = 'Add Section';

    effect(() => {
      const data = this.rfpData();
      this.displayList = data?.specificQuestionSection ? [...data.specificQuestionSection] : [];
      this.initialDisplayLists = this.utilityMethod.cloneDeepObject(this.displayList);
    });
  }

  ngOnInit(): void {
    this.userService.settingsToggler.subscribe((data: any) => {
      if (data?.sections?.[0]) {
        this.addSpecificSection(data?.sections[0]);
      }
    });
    this.loadFileFormats();
  }

  initEditor = (fieldName) => this.tinyMceService.getInitialConfigs('', `Enter ${fieldName || 'details'}`);

  isDownloadable(key: string) {
    if (!key) return false;
    return this.utilityMethod.isDownloadSupported(key);
  }

  isViewable(key: string) {
    if (!key) return false;
    return this.utilityMethod.isPreviewAvailable(key);
  }

  async loadFileFormats() {
    const data = await this.utilityMethod.loadAllowedFileFormats();
    this.fileList = data.fileList;
    this.formatList = data.formatList;
    this.documentDetails.allowedFiles = data.allowedFiles;
  }

  dropSection(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.displayList, event.previousIndex, event.currentIndex);
      this.saveSectionDetails();
    }
  }

  dropQuestion(event: CdkDragDrop<string[]>, sectionIndex: number) {
    if (event.previousIndex !== event.currentIndex) {
      const questions = this.displayList[sectionIndex].questions;
      moveItemInArray(questions, event.previousIndex, event.currentIndex);
      this.saveSectionDetails();
    }
  }

  openOnBoardingDrawer(type: OnBoardingEditDrawerType, index?: number, questionIndex?: number) {
    let drawerDetails;
    switch (type) {
      case OnBoardingEditDrawerType.AddSpecificQuestion:
        drawerDetails = JSON.parse(
          JSON.stringify({
            ...DefaultDrawerDetails[type],
            sectionName: this.displayList?.[index]?.title,
            id: this.displayList?.[index]?.id,
          })
        );
        break;
      case OnBoardingEditDrawerType.EditSpecificSection:
        drawerDetails = JSON.parse(
          JSON.stringify({
            sectionName: this.displayList?.[index]?.title,
            id: this.displayList?.[index]?.id,
          })
        );
        break;
      case OnBoardingEditDrawerType.EditSpecificQuestion:
        drawerDetails = this.getOnBoardingDrawerDetailsForEdit(index, questionIndex);
        break;
      case OnBoardingEditDrawerType.AddSpecificSection:
        drawerDetails = JSON.parse(JSON.stringify({ ...DefaultDrawerDetails[type] }));
        break;
      default:
        break;
    }
    this.userService.settingsToggler.emit({ toggle: 'specificQuestion', drawerDetails: { type: type, drawerDetails: drawerDetails } });
  }

  addSpecificSection(data) {
    const existingSection = this.displayList.find((section) => section.id === data.id);
    if (existingSection) {
      existingSection.title = data.sectionName;

      data.questions.forEach((newQuestion) => {
        const existingQuestionIndex = existingSection.questions.findIndex((q) => q?.id === newQuestion.id);
        if (existingQuestionIndex !== -1) {
          existingSection.questions[existingQuestionIndex] = { ...newQuestion, id: uuidv4()};
        } else {
          existingSection.questions.push(newQuestion);
        }
      });
    } else {
      let newSection = {
        id: data.id,
        title: data.sectionName,
        questions: data.questions ? [...data.questions] : [],
      };
      this.displayList.push(newSection);
    }
    this.saveSectionDetails();
  }

  deleteSpecificSection(sectionId: string, sectionTitle: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the below section?`;
    dialogRef.componentInstance.input = `${sectionTitle}`
    dialogRef.componentInstance.acceptanceText = 'Delete';

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.displayList = this.displayList.filter((q) => q.id !== sectionId);
        this.saveSectionDetails();
        this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform("Section deleted Successfully", { ns: 'Snackbar' }))
      }
    });
  }

  getOnBoardingDrawerDetailsForEdit(parentIndex: number, childIndex: number) {
    const questionField = JSON.parse(JSON.stringify(this.displayList[parentIndex].questions[childIndex]));
    const section = JSON.parse(JSON.stringify(this.displayList[parentIndex]));
    return {
      id: section.id,
      sectionName: section.title,
      questions: [
        {
        question: questionField.fieldTitle,
        answerType: questionField.fieldType, 
        id: questionField.id,
        multipleChoice: questionField?.multipleChoice, 
        singleChoice: questionField?.singleChoice,
        allowMultipleChoice: questionField?.allowMultipleChoice, 
        options: questionField?.allowMultipleChoice ? questionField?.multipleChoice : questionField?.singleChoice
        },
      ],
      document: questionField.template,
    };
  }

  async saveSectionDetails() {
    this.userService.rfpSpecificationQuestions$.next(this.displayList);
  }

  deleteSpecificQuestion(sectionId: string, questionId: string, questionTitle: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the below question?`;
    dialogRef.componentInstance.input = `${questionTitle}`
    dialogRef.componentInstance.acceptanceText = 'Delete';

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const section = this.displayList.find((s) => s.id === sectionId);
        if (section) {
          section.questions = section.questions.filter((q) => q.id !== questionId);
          this.saveSectionDetails();
          this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform("Question deleted Successfully", { ns: 'Snackbar' }));
        }
      }
    });
  }
  async downloadDocument(key: string, name: string ) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: key, fileName: name }).toPromise();
      window.open(response?.data?.viewUrl)
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  async viewDocument(fileKey: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }
}
