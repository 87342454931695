import { Component, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { DomSanitizer } from '@angular/platform-browser';
import { I18NextModule } from 'angular-i18next';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'conpulse-web-specific-question-view-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, MatChipsModule, I18NextModule, MatButtonModule],
  templateUrl: './specific-question-view-dialog.component.html',
  styleUrl: './specific-question-view-dialog.component.scss',
})
export class SpecificQuestionViewDialogComponent {

  readonly sanitizer = inject(DomSanitizer);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { ans: any, question: any  }) {}

  safeHtml(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
