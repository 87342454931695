import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { UserService, NotificationService, CONSTANTS } from '@conpulse-web/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'conpulse-web-account-and-billing',
  templateUrl: './account-and-billing.component.html',
  styleUrls: ['./account-and-billing.component.scss']
})
export class AccountAndBillingComponent implements OnInit {
  isLoading: boolean = false;
  routerSubscription: Subscription | null = null;
  currentUrl: string[] = [];
  isFirm = false

  constructor(private userService: UserService, private notificationService: NotificationService, private router: Router) {
    this.routerSubscription = router.events.pipe(
      filter((event) => event instanceof Scroll)
    ).subscribe(({ routerEvent }: Scroll) => {
      if (routerEvent instanceof NavigationEnd && routerEvent.url) {
        this.currentUrl = routerEvent.urlAfterRedirects.split('/')
      }
    });
  }

  ngOnInit(): void {
    this.isFirm = CONSTANTS.CF_PROFILE_ROLES.includes(this.userService.currentUserInformation?.role)
  }

}
