@if(displayList?.length) {
<div class="questionnaire-section w-50" cdkDropList (cdkDropListDropped)="dropSection($event)">
    @for (section of displayList; track $index; let i = $index) {
    @let sectionNumber = i + 1;
    <div class="section-container section mt-3" cdkDrag cdkDragBoundary=".questionnaire-section"
        [cdkDropListDisabled]="section?.length">
        <div class="drag-custom-placeholder mt-2 border-radius-xlarge" *cdkDragPlaceholder></div>
        <div class="title-container d-flex align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center">
                    @if(displayList?.length > 1 && !restrictEdit() && !isProvider()) {
                        <div cdkDragHandle>
                            <mat-icon class="cursor-move txt-grey-secondary7 d-flex align-items-center f-20"
                                fontIcon="drag_indicator"></mat-icon>
                        </div>
                        }                    
                <h3 class="f-16 f-w-600 l-h-28">{{tabNumber()}}.{{sectionNumber }}. {{ section?.title }}</h3>
            </div>
            @if (!restrictEdit() && !isProvider()) {
            <div class="icon-groups">
                <mat-icon class="f-20 pointer border-radius-full p-1 more-actions"
                    [matMenuTriggerFor]="sectionsMenu">more_vert</mat-icon>
                <mat-menu #sectionsMenu="matMenu">
                    <button mat-menu-item (click)="openOnBoardingDrawer(onBoardingDrawerType.EditSpecificSection, i)">
                        <mat-icon fontIcon="edit"></mat-icon>{{ 'Edit' | i18next }}
                    </button>
                    <button mat-menu-item class="delete-action" (click)="deleteSpecificSection(section?.id, section?.title)">
                        <mat-icon fontIcon="delete"></mat-icon>{{ 'Delete' | i18next }}
                    </button>
                </mat-menu>
            </div>
            }
        </div>
        <div class="question-container" cdkDropList (cdkDropListDropped)="dropQuestion($event, i)">
            @for (questions of section?.questions; track $index; let j = $index) {
            @let questionNumber = j + 1;
            <div class="question-section d-flex align-items-start justify-content-between section mt-2" cdkDrag
                cdkDragBoundary=".question-container">
                <div class="drag-custom-placeholder m-t-12 border-radius-xlarge" *cdkDragPlaceholder></div>
                <div class="d-flex align-items-start w-100">
                    @if(section?.questions?.length > 1 && !restrictEdit() && !isProvider()) {
                    <div cdkDragHandle>
                        <mat-icon class="cursor-move txt-grey-secondary7 f-20 d-flex align-items-center"
                            fontIcon="drag_indicator"></mat-icon>
                    </div>
                    }
                    <div class="ml-2 w-100">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="f-14 f-w-600 l-h-28 txt-primary-01"> {{tabNumber()}}. {{ sectionNumber }}. {{
                                questionNumber }}. {{
                                questions?.fieldTitle }}</p>
                            @if(!restrictEdit() && !isProvider()) {
                            <div class="question-icon">
                                <mat-icon class="f-20 pointer border-radius-full p-1 more-actions"
                                    [matMenuTriggerFor]="questionsMenu">more_vert</mat-icon>
                                <mat-menu #questionsMenu="matMenu">
                                    <button mat-menu-item
                                        (click)="openOnBoardingDrawer(onBoardingDrawerType.EditSpecificQuestion, i, j)">
                                        <mat-icon fontIcon="edit"></mat-icon>{{ 'Edit' | i18next }}
                                    </button>
                                    <button mat-menu-item class="delete-action"
                                        (click)="deleteSpecificQuestion(section?.id, questions?.id, questions.fieldTitle)">
                                        <mat-icon fontIcon="delete"></mat-icon>{{ 'Delete' | i18next }}
                                    </button>
                                </mat-menu>
                            </div>
                            }
                        </div>
                        @switch (questions?.fieldType) {
                        @case ('upload') {
                        <div>
                            <label class="f-14 mt-3">{{ 'Document upload' | i18next }}</label>
                            <conpulse-web-file-input [accept]="formatList" [disabled]="true" [fileLimit]="fileList"
                                [maxCount]="fileLimit" [multiple]="false"/>
                        </div>
                        }
                        @case ('template') {
                        @let doc = questions?.template;
                        <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">{{'Template Format' | i18next }}</p>
                        <div
                            class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between">
                            <div class="file-details w-80">
                                <p class="f-13 f-w-500 w-100 text-truncate">
                                    {{doc?.documentType === 2 ?
                                    doc?.documentKey : doc?.documentName}}
                                </p>
                            </div>
                            <div class="file-actions">
                                @if(isDownloadable(doc?.documentKey) &&
                                isViewable(doc?.documentKey)) {

                                <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                                    [matMenuTriggerFor]="actionMenu" fontIcon="more_vert"></mat-icon>
                                <mat-menu #actionMenu="matMenu">
                                    @if(isDownloadable(doc?.documentKey)) {
                                    <button mat-menu-item
                                        (click)="downloadDocument(doc?.documentKey, doc?.documentName)">
                                        <mat-icon fontIcon="download"></mat-icon> {{'Download' | i18next}}
                                    </button>
                                    }
                                    @if(isViewable(doc?.documentKey)) {
                                    <button mat-menu-item (click)="viewDocument(doc?.documentKey, doc?.documentType)">
                                        <mat-icon fontIcon="visibility"></mat-icon> {{'View' | i18next}}
                                    </button>
                                    }
                                </mat-menu>

                                } @else {
                                @if(isDownloadable(doc?.documentKey)) {
                                <button mat-stroked-button color="primary" class="small mr-1"
                                    (click)="downloadDocument(doc?.documentKey, doc?.documentName)">
                                    {{ 'Download' | i18next }}
                                </button>
                                }
                                @if(isViewable(doc?.documentKey)) {
                                <button mat-stroked-button color="primary" class="small mr-1"
                                    (click)="viewDocument(doc?.documentKey, doc?.documentType)">
                                    {{ 'View' | i18next }}
                                </button>
                                }
                                }
                            </div>
                        </div>
                        <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">Template Value</p>
                        <div>
                            <label class="f-14 mt-3">{{ 'Document upload' | i18next }}</label>
                            <conpulse-web-file-input [accept]="formatList" [disabled]="true" [fileLimit]="fileList"
                                [maxCount]="fileLimit" [multiple]="false"></conpulse-web-file-input>
                        </div>
                        }
                        @case ('multipleChoice') {
                        @if(questions?.allowMultipleChoice ) {
                        <div class="d-flex flex-column mt-2 mb-2 disabled">
                            @for (option of questions?.multipleChoice; track option) {
                            <mat-checkbox [disabled]="true">
                                <span>{{ option }}</span>
                            </mat-checkbox>
                            }
                        </div>
                        } @else {
                        <div class="d-flex mt-2 mb-2">
                            <mat-radio-group [disabled]="true" class="radio-button">
                                @for (option of questions?.singleChoice; track option) {
                                <div>
                                    <mat-radio-button [value]="option">{{ option }}</mat-radio-button>
                                </div>
                                }
                            </mat-radio-group>
                        </div>
                        }
                        }
                        @default {
                        <div class="template-editor-field mb-3 quill-bordered mt-3 w-100 quill-height-200 disabled"
                            [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                            <editor [init]="initEditor(('Please provide details on ' | i18next) + questions.fieldTitle)"
                                [disabled]="true" name="{{ 'quillEditor' + j }}">
                            </editor>
                        </div>
                        }
                        }
                    </div>
                </div>
            </div>

            }
        </div>
        @if (!isProvider() && !restrictEdit()) {
        <button type="button" color="primary" class="mt-3" class="medium" mat-button
            (click)="openOnBoardingDrawer(onBoardingDrawerType.AddSpecificQuestion, i)">
            <mat-icon class="mat-20 pointer mr-1" fontIcon="add"></mat-icon><span>{{ 'Add Question' | i18next }}</span>
        </button>
        }
    </div>
    }
    @if(!isProvider() && !restrictEdit()) {
        <div class="my-3">
            <button type="button" color="primary" class="medium" mat-stroked-button
                (click)="openOnBoardingDrawer(onBoardingDrawerType.AddSpecificSection)">
                <mat-icon class="mat-20 pointer mr-1" fontIcon="add"></mat-icon><span>{{ 'Add Section' | i18next }}</span>
            </button>
        </div>
    }
</div>

} @else {
<conpulse-web-no-content [data]="noData" [isProjectPaused]="restrictEdit()" (addEmitter)="openOnBoardingDrawer(onBoardingDrawerType.AddSpecificSection)" />
}