import { Component, effect, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18NextModule, I18NextPipe } from 'angular-i18next';
import { FileInputModule } from '../file-input/file-input.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { ConpulseMaterialModule } from '../../material-module';
import { ConpulseModule } from '../../conpulse.module';
import { isEqual } from 'lodash-es';
import {
  DeleteConfirmationComponent,
  NotificationService,
  SpecificQuestionAnswer,
  UploaderService,
  UploadFileDetails,
  UserService,
  ViewType,
} from '@conpulse-web/core';
import { TinyMceService, UtilityMethodsService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'conpulse-web-common-specific-question-ans',
  standalone: true,
  imports: [CommonModule, I18NextModule, FileInputModule, EditorModule, FormsModule, ConpulseMaterialModule, ConpulseModule],
  templateUrl: './common-specific-question-ans.component.html',
  styleUrl: './common-specific-question-ans.component.scss',
})
export class CommonSpecificQuestionAnsComponent {
  isLoading = input(false);
  isRestrict = input(false);
  uploadType = input<string>('');
  isRfp = input(false);
  // projectId or rfiId
  id = input('');
  clientId = input('');
  displayList = input<SpecificQuestionAnswer[]>([]);

  getAnsData = output<SpecificQuestionAnswer[]>();
  goBack = output();
  next = output();
  initialDisplayList: SpecificQuestionAnswer[] = [];
  questionList: SpecificQuestionAnswer[] = [];

  isEqual = isEqual;

  formatList: string[] = [];
  fileList: any[] = [];

  private userService: UserService = inject(UserService);
  private notificationService: NotificationService = inject(NotificationService);
  private tinyMceService: TinyMceService = inject(TinyMceService);
  private uploaderService: UploaderService = inject(UploaderService);
  private utilityMethod: UtilityMethodsService = inject(UtilityMethodsService);
  private dialog = inject(MatDialog);
  private i18nextPipe = inject(I18NextPipe)

  constructor() {
    effect(
      () => {
        const data = this.displayList();
        this.initialDisplayList = this.utilityMethod.cloneDeepObject(data);
        this.questionList = this.utilityMethod.cloneDeepObject(data);
      },
    );
  }

  ngOnInit() {
    this.loadFileFormats();
  }

  async loadFileFormats() {
    try {
      const data = await this.utilityMethod.loadAllowedFileFormats();
      this.fileList = data.fileList;
      this.formatList = data.formatList;
    } catch (error) {
      this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Failed to load file formats'));
    }
  }

  isSelected(answer: any[], option: string): boolean {
    return answer?.includes(option) || false;
  }

  onCheckboxChange(event: any, question: any, option: string) {
    if (event.checked) {
      question.multipleChoiceAnswers.push(option);
    } else {
      question.multipleChoiceAnswers = question.multipleChoiceAnswers.filter((item: string) => item !== option);
    }
  }

  async downloadDocument(key: string, name: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: key, fileName: name }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Could not download the document'));
    }
  }

  async viewDocument(fileKey: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Could not view the document'));
    }
  }

  async uploadDocument(event: any, sectionIndex: number, questionIndex: number) {
    const doc = event[0];
    if (!doc) {
      this.questionList[sectionIndex].questions[questionIndex].file = null;
      this.questionList[sectionIndex].questions[questionIndex].doc = [];
    } else {
      const question = this.questionList[sectionIndex]?.questions[questionIndex];
      question.file = doc?.file;
      question.document = {
        documentName: doc?.name,
        documentType: doc?.type,
        documentKey: doc?.key,
      };
      if ((question?.fieldType === 'upload' || question?.fieldType === 'template') && question?.document) {
        const file = question?.file;
        if (file) {
          const filePath = await this.uploadDocumentToS3(file);
          if (filePath) {
            question.document.documentKey = filePath;
          } else {
            this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Failed to upload document to S3'));
            return;
          }
        }
      }
    }
  }  

  async uploadDocumentToS3(file: File): Promise<string | null> {
    const fileName = file.name;
    const mimeType = file.type;
    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    const fileDetails: UploadFileDetails = {
      uploadType: this.uploadType(),
      fileKey: `${uuidv4()}.${fileType}`,
      mimeType: mimeType,
    };
    try {
      const urls = await this.uploaderService
        .getUploadUrl(fileDetails, {
          providerId: this.userService.currentUserInformation.firmId?.['_id'],
          [this.isRfp() ? 'projectId' : 'rfiId']: this.id(),
          clientId: this.clientId(),
        })
        .toPromise();
      const uploadUrl: string = urls?.data?.uploadUrl;
      if (uploadUrl) {
        await this.uploaderService.uploadToPresignedUrl(uploadUrl, file, mimeType).toPromise();
        return urls.data.filePath;
      } else {
        this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Could not get upload URL'));
        return null;
      }
    } catch (err) {
      this.notificationService.openErrorSnackBar(this.i18nextPipe.transform('Could not upload document to S3'));
      return null;
    }
  }

  isViewable(key: string): boolean {
    return this.utilityMethod.isPreviewAvailable(key);
  }

  isDownloadable(key: string): boolean {
    return this.utilityMethod.isDownloadSupported(key);
  }

  initEditor = (fieldName: string) => this.tinyMceService.getInitialConfigs('', `Enter ${fieldName || 'details'}`);

  deleteDocument(questions: any) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the Document?`;
    dialogRef.componentInstance.acceptanceText = 'Delete';

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        questions.document = null;
      }
    });
  }

  submitAnswers() {
    this.getAnsData.emit(this.questionList)
  }
}
