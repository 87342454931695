import { Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CONSTANTS, NotificationService, provideHeader, QuestionnaireItem, UploaderService, UserService, ViewType } from '@conpulse-web/core';
import { MatTableModule } from '@angular/material/table';
import { I18NextModule } from 'angular-i18next';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { CommonService, UtilityMethodsService } from '../../services';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { SpecificQuestionViewDialogComponent } from '../specific-question-view-dialog/specific-question-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'conpulse-web-specific-question-comparison-drawer',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTableModule, I18NextModule, MatButtonModule, MatChipsModule, MatCheckboxModule, MatRadioModule, MatMenuModule],
  templateUrl: './specific-question-comparison-drawer.component.html',
  styleUrl: './specific-question-comparison-drawer.component.scss',
})
export class SpecificQuestionComparisonDrawerComponent {
  data = input<{ questionnaireItem: QuestionnaireItem[]; questionId: string }>({ questionnaireItem: [], questionId: '' });
  back = output();
  source = [];
  displayedColumns = ['provider', 'ans'];
  isProvider: boolean = false;
  totalCount = 0;
  preNextCount = signal(0);
  question: any = {};
  userRole: number;
  constants = CONSTANTS;
  viewProposal: boolean = false;

  private uploaderService = inject(UploaderService);
  private notificationService = inject(NotificationService);
  private userService = inject(UserService);
  public utilityService = inject(UtilityMethodsService);
  readonly dialog = inject(MatDialog);
  private commonService = inject(CommonService);

  constructor() {
    effect(
      () => {
        const value = this.data();
        const index = value.questionnaireItem.findIndex((data) => data.question.id === value.questionId);
        this.preNextCount.set(index);
        this.totalCount = value.questionnaireItem.length - 1;
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      if (this.preNextCount() <= this.totalCount && this.preNextCount() >= 0) {
        this.processData();
      }
    });
  }

  ngOnInit() {
    this.userRole = this.userService?.currentUserInformation?.role;
    this.isProvider = CONSTANTS.CF_PROFILE_ROLES.includes(this.userRole);
    this.commonService.isProposalAnswerVisible.subscribe((data) => this.viewProposal = data);
    this.displayedColumns = ['provider', 'ans'];
  }

  processData() {
    if (this.preNextCount() < 0) return;

    const { question, section, position, sectionIndex, isQuestion, ...ansData } = { ...this.data().questionnaireItem[this.preNextCount()] };
    this.question = {
      question,
      section,
      position,
      sectionIndex,
      isQuestion
    }
    this.source = Object.values(ansData);
  }

  moveNextQuestion(): void {
    this.preNextCount.update((val) => val + 1);
  }

  movePrevQuestion(): void {
    this.preNextCount.update((val) => val - 1);
  }

  async downloadDocument({ documentKey, documentName }) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: documentKey, fileName: documentName }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  async viewDocument({ documentKey, documentType }) {
    try {
      if (documentType == 1) {
        window.open(documentKey);
        return;
      }
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey: documentKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  getProfileClass(i: number) {
    const modvalue = i % 4;
    switch (modvalue) {
      case 0:
      default:
        return 'orange';
      case 1:
        return 'blue';
      case 2:
        return 'dark-green';
      case 3:
        return 'red';
    }
  }

  checkTruncation(element: HTMLElement): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  truncateDocumentName(name: string): string {
    if (name.length <= 10) return name;

    const [base, ext = ''] = name.split('.');
    return `${base.slice(0, 5)}...${base.slice(-2)}${ext ? '.' + ext : ''}`;
  }


  openDialog(ans): void {
      const dialogRef = this.dialog.open(SpecificQuestionViewDialogComponent, {
        data: {question: this.question, ans},
        disableClose: true,
      });
    }
}
