import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trim } from 'lodash-es';
import { DeleteConfirmationComponent, DynamicFormsValue, DynamicTemplateSections, NotificationService, TemplateDocType, UploadFileDetails, UploadType, UploaderService } from '@conpulse-web/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'conpulse-web-dynamic-sections',
  templateUrl: './dynamic-sections.component.html',
  styleUrls: ['./dynamic-sections.component.scss'],
})
export class DynamicSectionsComponent implements OnInit {
  @Input() sections: DynamicTemplateSections[] = [];
  @Input() tabName: string;
  @Input() superSectionIndex: number = 0;
  @Output() onAddAppendixSections: EventEmitter<DynamicTemplateSections[]> = new EventEmitter();
  @Output() onChangeSectionTitle: EventEmitter<{ title: string; index: number }> = new EventEmitter();
  @Output() isValidSection: EventEmitter<boolean> = new EventEmitter();
  trim = trim;
  constructor(
    private readonly dialog: MatDialog,
    private uploaderService: UploaderService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  /**
   * trackByFn for primitive type loop
   */
  trackByFn(index: number) {
    return index;
  }

  onTitleChanges(index: number) {
    this.onChangeSectionTitle.emit({ title: this.sections[index]?.title, index: index });
    this.validateSection();
  }

  /**
   * Drag/drop event for section
   * @param event DragDrop event
   */
  dropSection(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
      this.onAddAppendixSections.emit([...this.sections].map((data) => ({ ...data })));
    }
  }

  /**
   * The function adds a new section to an array at a specified index.
   * @param {number} index - The index parameter specifies the position at which the new section should
   * be added in the sections array.
   */
  addSection(index: number) {
    this.sections.splice(index, 0, {
      fields: [],
      title: '',
    });
    this.validateSection();
  }

  /**
   * The function updates the fields of a section in a dynamic form, uploads new
   * documents to S3 if necessary, and emits an event with the updated sections.
   * @param {number} index - The `index` parameter is the index of the section in the `sections` array
   * that needs to be updated.
   */
  async onAddDynamicFields(
    values: {
      added: DynamicFormsValue[];
    },
    index: number
  ) {
    let sectionUpdated = [...this.sections].map((data) => ({ ...data }));
    this.sections[index].fields = [...values.added].map((data) => ({ ...data }));
    sectionUpdated[index].fields = values?.added.filter((values: DynamicFormsValue) => {
      if (values?.title) {
        return values;
      }
    });
    this.validateSection();
    this.onAddAppendixSections.emit([...sectionUpdated]);
  }

  /**
   * The function checks if any section in an array has missing or invalid fields or titles and emits a
   * boolean value indicating if the section is valid or not.
   */
  validateSection() {
    const invalidSection = this.sections.some((data) => !data?.fields?.length || data?.fields.some((field) => !field?.title?.length) || !data?.title?.length);
    this.isValidSection.emit(invalidSection);
  }

  /**
   * The removeSection function removes a section from an array, validates the remaining sections, and
   * emits an event with the updated sections.
   * @param {number} indexToDelete - The index of the section that you want to delete from the `sections`
   * array.
   */
  removeSection(indexToDelete: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      disableClose: true,
      width: '450px',
    });
    dialogRef.componentInstance.title = 'Delete';
    dialogRef.componentInstance.message = `Are you sure you want to delete the below section?`;
    dialogRef.componentInstance.input = `<b>${this.sections[indexToDelete]?.title}</b>`
    dialogRef.componentInstance.acceptanceText = 'Delete';
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.sections.splice(indexToDelete, 1);
        this.validateSection();
        this.onAddAppendixSections.emit([...this.sections]);
      }
    });
  }
}
