import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { CONSTANTS, InvoiceStatus, TableData, UserService, NotificationService, UploaderService, ASSETS, DeleteConfirmationComponent } from '@conpulse-web/core';
import { isEmpty } from 'lodash-es';
import { MyCustomPaginatorIntl } from '../../../services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'conpulse-web-billing-table',
  templateUrl: './billing-table.component.html',
  styleUrls: ['./billing-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
})
export class BillingTableComponent implements OnInit {
  @Input() tableValues = {} as TableData;
  @Input() type = '';
  @Input() errorMessage: string = undefined;
  @Input() isPagination = true;
  @Input() currencySymbol: any;
  @Input() minorUnits: any;
  @Output() changed = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Input() isSuperAdmin = false;
  @Input() isOverallBilling = false;
  invoiceStatus = InvoiceStatus;
  isEmpty = isEmpty;
  defaultProfilePhoto = CONSTANTS.DEFAULT_USER_PROFILE_IMAGE;
  notFoundImage: string;

  constructor(private notificationService: NotificationService, private uploaderService: UploaderService, private userService: UserService,
    private dialog: MatDialog,) {
    this.notFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }
  ngOnInit(): void {}

  editElement(data: any, status?: InvoiceStatus) {
    if (!status) {
      this.edit.emit({
        id: data?._id,
        status,
        currency: data?.currency?._id,
        minorUnit: data?.currency?.minorUnits,
        clientId: data?.clientId,
        applicableTax: data?.taxPerc
      });
    } else {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        disableClose: true,
        width: '450px',
      });

      dialogRef.componentInstance.title = (status === InvoiceStatus.OPEN) ? 'Finalize Invoice' : 'Modify status';
      dialogRef.componentInstance.message = (status === InvoiceStatus.OPEN) ? 'Are you sure you want to Finalize the Invoice?' : `Are you sure you want to change the status to the below status?`;
      dialogRef.componentInstance.input = (status !== InvoiceStatus.OPEN) ? `<b>${status}</b>` : ''
      dialogRef.componentInstance.acceptanceText = 'Yes';

      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.edit.emit({
            id: data?._id,
            status,
            currency: data?.currency?._id,
            minorUnit: data?.currency?.minorUnits,
            clientId: data?.clientId,
            applicableTax: data?.taxPerc
          });
        }
      });
    }
  }

  checkButtonVisibility(action: string, status: InvoiceStatus) {
    switch (action) {
      case this.invoiceStatus.OPEN:
        return status === this.invoiceStatus.DRAFT;
      case this.invoiceStatus.VOID:
        return ![this.invoiceStatus.DRAFT, this.invoiceStatus.VOID, this.invoiceStatus.PAID].includes(status);
      case this.invoiceStatus.UNCOLLECTIBLE:
        return status === this.invoiceStatus.OPEN;
      case this.invoiceStatus.PAID_OUT_OF_BAND:
        return [this.invoiceStatus.OPEN, this.invoiceStatus.UNCOLLECTIBLE].includes(status);
    }
  }

  /**To change the page */
  changePage(value: string) {
    this.tableValues.selectedData = [];
    switch (value) {
      case '-':
        if (this.tableValues.page - 1 >= 0) {
          this.tableValues.page = this.tableValues.page - 1;
          this.changed.emit();
        }
        break;
      case '+':
        if (this.tableValues.page + 1 < this.tableValues.endPage) {
          this.tableValues.page = this.tableValues.page + 1;
          this.changed.emit();
        }
        break;
      default:
        if (parseInt(value, 10) - 1 !== this.tableValues.page) {
          this.tableValues.page = parseInt(value, 10) - 1;
          this.changed.emit();
        }
        break;
    }
  }

  async viewPdf(key: string) {
    try {
      const response = await this.uploaderService.getFileViewUrl({ folderPath: '/', fileKey: key }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the file`);
    }
  }

  /**
   * Handles next page click
   * @param event page click event consits pageIndex, previousPageIndex,pageSize,length
   */
  handlePageEvent(event: PageEvent) {
    this.tableValues.page = event.pageIndex;
    this.tableValues.limit = event.pageSize;
    this.changed.emit();
  }

  getClass(i: number) {
    const modvalue = i % 4;
    switch (modvalue) {
      case 0:
        return 'orange';
      case 1:
        return 'blue';
      case 2:
        return 'dark-green';
      case 3:
        return 'red';
      default:
        return 'orange';
    }
  }

  openUrl(url: string) {
    window.open(url);
  }
}
