<div class="specific-question-container p-20">
  @if (!isLoading()) {
  <div class="questionnaire-section w-50">
    @for (section of questionList; track section; let i = $index) {
    @let sectionIndex = i + 1;
    <div class="section-container section mt-3">
      <div class="title-container d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <h3 class="f-16 f-w-600 l-h-28">{{ sectionIndex }}. {{ section?.title }}</h3>
        </div>
      </div>
      <div class="question-container">
        @for (questions of section?.questions; track questions; let j = $index) {
        <div class="question-section d-flex align-items-start justify-content-between section mt-2">
          <div class="ml-2 w-100">
            <div class="d-flex align-items-center justify-content-between">
              <p class="f-14 f-w-600 l-h-28 txt-primary-01">{{ sectionIndex }}.{{ j + 1 }}. {{ questions?.fieldTitle }}
              </p>
            </div>
            @switch (questions?.fieldType) {
            @case ('upload') {
            <div class="upload-ref">
              <label class="f-14 mt-3">{{ 'Document upload' | i18next }}</label>
              <conpulse-web-file-input [onlyDocumentUpload]="false" [document]="questionList[i].questions[j].doc"
                [accept]="formatList" [disabled]="isRestrict()" (download)="downloadDocument($event)"
                [fileLimit]="fileList" [maxCount]="fileLimit" [multiple]="false"
                (docUploaded)="uploadDocument($event, i, j)" [showDocViewBtn]="true"
                (view)="viewDocument($event)"></conpulse-web-file-input>
            </div>
            }
            @case ('multipleChoice') {
            @if(questions?.allowMultipleChoice ) {
            <div class="d-flex flex-column mt-2 mb-2">
              @for (option of questions?.multipleChoice; track option) {
              <mat-checkbox [checked]="isSelected(questions.multipleChoiceAnswers, option)"
                (change)="onCheckboxChange($event, questions, option)" [disabled]="isRestrict()">
                <span>{{ option }}</span>
              </mat-checkbox>
              }
            </div>
            } @else {
            <div class="d-flex mt-2 mb-2">
              <mat-radio-group class="radio-button" color="secondary" [(ngModel)]="questions.answer"
                [disabled]="isRestrict()">
                @for (option of questions?.singleChoice; track option) {
                <mat-radio-button [value]="option">{{ option }}</mat-radio-button>
                }
              </mat-radio-group>
            </div>
            }
            }
            @case ('template') {
            <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">{{'Template Format' | i18next}}</p>
            <ng-container>
              <div
                class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between">
                <div class="file-details w-80">
                  <p class="f-13 f-w-500 w-100 text-truncate">{{questions?.template?.documentType === 2 ?
                    questions?.template?.documentKey : questions?.template?.documentName}}</p>
                </div>
                <div class="file-actions">
                  <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                    [matMenuTriggerFor]="actionMenu">more_vert</mat-icon>
                  <mat-menu #actionMenu="matMenu">
                    <button mat-menu-item *ngIf="isDownloadable(questions.template.documentKey)"
                      (click)="downloadDocument(questions?.template?.documentKey, questions?.template?.documentName)">
                      <mat-icon>download</mat-icon> {{'Download' | i18next}}
                    </button>
                    <button mat-menu-item *ngIf="isViewable(questions?.template?.documentKey)"
                      (click)="viewDocument(questions?.template.documentKey, questions?.template.documentType)">
                      <mat-icon>visibility</mat-icon> {{'View' | i18next}}
                    </button>
                  </mat-menu>
                </div>
              </div>
            </ng-container>
            <p class="f-14 f-w-600 l-h-20 mt-3 mb-2">{{'Template Value' | i18next}}</p>
            <div class="upload-ref">
              <label class="f-14 mt-3">{{ 'Template upload' | i18next }}</label>
              <conpulse-web-file-input [onlyDocumentUpload]="false" [document]="questionList[i].questions[j].doc"
                [accept]="formatList" [disabled]="isRestrict()" (download)="downloadDocument($event)"
                [fileLimit]="fileList" [maxCount]="fileLimit" [multiple]="false"
                (docUploaded)="uploadDocument($event, i, j)" [showDocViewBtn]="true"
                (view)="viewDocument($event)"></conpulse-web-file-input>
            </div>
            } @default {
            <div class="template-editor-field mb-3 quill-bordered mt-3 w-100 quill-height-200"
              [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading(), 'disabled': isRestrict() }">
              <editor [init]="initEditor(('Please provide details on ' | i18next) + questions.fieldTitle)"
                name="{{ 'quillEditor' + j }}" [(ngModel)]="questions.answer">
              </editor>
            </div>
            }

            }
          </div>
        </div>
        }
      </div>
    </div>
    }
  </div>
  } @else {
  <div class="dashboard-box-loader p-20">
    <div class="skeleton-loader loader w-100"></div>
  </div>
  }
</div>
<div class="bg-white border-top d-flex align-items-center justify-content-end border-radius-6 pl-3 p-r-72 py-3">
  @let isNext = isEqual(questionList, initialDisplayList) || isRestrict();
  @if(!isRfp()) {
  <button mat-stroked-button color="primary" class="icon-button mr-2" (click)="goBack.emit()">
    <mat-icon class="mat-20 mr-2">arrow_back_ios</mat-icon>{{ 'Back' | i18next }}
  </button>

  <button class="pb-30" color="primary" type="button" mat-flat-button (click)="isNext ? next.emit() : submitAnswers()"
    [disabled]="isLoading()">
    {{ isNext ? ('Next' | i18next) : ('Save & Next' | i18next) }}
    @if(!isRfp()) {
    <mat-icon class="mat-20 ml-2" iconPositionEnd>arrow_forward_ios</mat-icon>
    }
  </button>

  } @else {
  <button class="pb-30" color="primary" type="button" mat-flat-button (click)="submitAnswers()"
    [disabled]="isNext || isLoading()">
    {{'Save' | i18next}}
  </button>
  }
</div>