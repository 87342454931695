import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ASSETS,
  AccountDetails,
  AdminService,
  CONSTANTS,
  ClientAccountTypes,
  NotificationService,
  UploaderService,
  UserService,
  ViewType,
  user,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '@conpulse-web/conpulse';
import { Subscription } from 'rxjs';
import { get, isEmpty } from 'lodash-es';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'conpulse-web-client-contracts',
  templateUrl: './client-contracts.component.html',
  styleUrls: ['./client-contracts.component.scss'],
})
export class ClientContractsComponent implements OnInit {
  date = new Date();
  routerSubscription: Subscription | null = null;
  companyId: string;
  isSuperAdmin: boolean = false;
  user: user = {} as user;
  isRecordExist: boolean = false;
  accountDetails: AccountDetails = {} as AccountDetails;
  accountTypes = ClientAccountTypes;
  clientCreatedAt: Date;
  isEmpty = isEmpty;
  isLoading: boolean = false;
  isCompanyLoading: boolean = false;
  isClient: boolean = false;
  notFoundImage: string;
  clientAdminDetails;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private utilityService: UtilityMethodsService,
    private readonly uploaderService: UploaderService
  ) {
    this.routerSubscription = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const params = this.utilityService.collectAllRouteParams(this.router);
      if (event.url) {
        const route = event.url.split('/');
        this.isClient = route[1] === 'dashboard';
        this.adminService.resetAccountDrawer();
        if (
          ![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.userService?.currentUserInformation?.role) ||
          !this.userService.currentUserInformation?.role
        ) {
          this.isSuperAdmin = true;
          this.isClient = route.includes('client');
          this.companyId = params?.id;
        }
      }
    });
    this.notFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }

  ngOnInit(): void {
    const params = this.utilityService.collectAllRouteParams(this.router);
    this.user = this.userService.currentUserInformation;
    const route = this.router.url.split('/');
    if (![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.user?.role) || !this.userService.currentUserInformation?.role) {
      this.isSuperAdmin = true;
      this.isClient = route.includes('client');
      this.companyId = params?.id;
    }
    this.adminService.getCfProfileDrawerState().subscribe((data) => {
      if (data === this.accountTypes.FirmContract) {
        this.openAddEditDrawer(data);
      }
    });
    this.getAccountDetails();
  }

  getCompanyById() {
    this.isCompanyLoading = true;
    this.adminService.getCompanyById(this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id']).subscribe(
      (response) => {
        this.clientAdminDetails = response?.data?.userId;
        this.isCompanyLoading = false;
        this.clientCreatedAt = response?.data?.createdAt;
      },
      (error) => {
        this.notificationService.openErrorSnackBar('Unable to fetch company profile');
        this.isCompanyLoading = false;
      }
    );
  }

  getFirmById() {
    this.adminService.getFirmById(this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id']).subscribe(
      (response) => {
        this.clientCreatedAt = response?.data?.createdAt;
        this.isCompanyLoading = false;
      },
      (error) => {
        this.notificationService.openErrorSnackBar('Unable to fetch company profile');
      }
    );
  }

  getAccountDetails() {
    this.isLoading = true;
    this.adminService
      .getAccountDetailsForClients(
        this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id'],
        this.isClient
      )
      .subscribe({
        next: (response) => {
          this.accountDetails = response?.data;
          if (this.accountDetails?.administrativeInfo?.adminContact?._id && !this.isClient) {
            this.clientAdminDetails = JSON.parse(JSON.stringify({ ...this.accountDetails.administrativeInfo.adminContact }));
          }
          if (this.isClient) {
            this.getCompanyById();
          } else {
            this.getFirmById();
            this.adminService.setContractEditVisibility(!!this.accountDetails?.contract?.length);
          }
          this.isLoading = false;
        },
        error: (error) => {
          if (error.code === 404) {
            this.isRecordExist = true;
          } else {
            this.isRecordExist = false;
            this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to get details'));
          }
          this.isLoading = false;
          if (this.isClient) {
            this.getCompanyById();
          } else {
            this.getFirmById();
            this.adminService.setContractEditVisibility(!!this.accountDetails?.contract?.length);
          }
        },
      });
  }

  openAddEditDrawer(type: string) {
    this.adminService.setAccountDrawerState({
      open: true,
      type: type,
      accountDetails: this.accountDetails,
      isClient: this.isClient,
      clientAdminDetails: this.clientAdminDetails,
      createdAt: this.clientCreatedAt,
    });
  }

  async downloadDocument(documentKey: string, documentName: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: documentKey, fileName: documentName }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  async viewDocument(fileKey: string) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  isPreviewAvailable(key: string) {
    return this.utilityService.isPreviewAvailable(key);
  }

  isDownloadable(key: string) {
    return this.utilityService.isDownloadSupported(key);
  }

}
