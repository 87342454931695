import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DOCUMENT_TYPE } from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';

@Component({
  selector: 'conpulse-web-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
})
export class FileCardComponent implements OnInit {
  @Input() document: { name: string; key: string; isUploaded: boolean; type: 1 | 2 };
  @Input() disabled: boolean = false;
  @Input() showDelete = true;
  @Input() nameMaxWidth: string = 'mw-300'
  @Input() showDocViewBtn = false;
  @Output() delete: EventEmitter<{ key: string }> = new EventEmitter();
  @Output() download: EventEmitter<{ key: string }> = new EventEmitter();
  @Output() view: EventEmitter<{ key: string }> = new EventEmitter();
  docType = DOCUMENT_TYPE;

  constructor(private utilityService: UtilityMethodsService) {}

  ngOnInit(): void {}

  isPreviewAvailable(key: string){
    return this.utilityService.isPreviewAvailable(key)
  }

  isDownloadable(key: string){
    return this.utilityService.isDownloadSupported(key)
  }

  viewDoc(): void {
    if (this.document.type === DOCUMENT_TYPE.LINK) {
      window.open(this.document.key);
      return;
    }
    this.view.emit(this.document);
  }
}
