<div
  class="company-page"
  [ngClass]="{
    'company-profile-panel': isConsultingPanel && !isClient,
    'client-company-profile-panel': isConsultingPanel && isClient
  }"
>
  <div class="company-sidenav-section p-16 mr-3 sticky-top bg-white">
    <!-- Profile Card -->
    <div class="profile-information border-radius-xlarge bg-white overflow-hidden z-index-1 mb-3">
      <div class="profile-background position-relative overflow-hidden" *ngIf="!isLoading">
        <img alt="" class="img-fit-cover" [src]="companyProfile?.coverPhoto || 'assets/images/company-cover-photo.png'" />
      </div>
      <div class="overflow-hidden position-relative profile-background skeleton-loader" *ngIf="isLoading"></div>
      <!-- TODO: Profile picture dynamic and edit -->
      <div class="profile-image position-relative">
        <div class="profile-image" *ngIf="!isLoading">
          <img
            alt=""
            class="w-100 border-radius-full border-white-6 img-fit-cover"
            [src]="companyProfile?.profilePhoto || 'assets/images/company-profile-photo.png'"
          />
        </div>
        <div class="profile-image img-fit-cover w-100 border-radius-full skeleton-loader" *ngIf="isLoading"></div>
      </div>
      <div class="text-center border-radius-xlarge pb-4">
        <div class="d-flex align-items-center justify-content-center mt-2">
          <div>
            <p class="txt-black text-width l-h-18 f-w-700 f-14 text-center profile-text">{{ companyProfile?.name }}</p>
          </div>
          <div *ngIf="companyProfile?.isOptedForMarketPlace && !isLoading">
            <img class="w-100 h-100" [src]="marketPlaceIcon" />
          </div>
        </div>
        <p class="txt-grey-secondary9 f-w-500 f-14 l-h-16 mt-2 text-center profile-text text-truncate" [matTooltip]="companyProfile?.tagline">
          {{ companyProfile?.tagline }}
        </p>
      </div>
    </div>

    <div class="company-list d-flex flex-column">
      <ng-container *ngIf="!CONSTANTS.allUserRole.includes(user?.role)">
        <a routerLink="account" [class.active]="'account' === routerUrl">{{ 'Account' | i18next }}</a>
      </ng-container>

      <!-- <ng-container *ngIf="!CONSTANTS.allUserRole.includes(user?.role)">
        <a (click)="navigateToRoute('contracts')"  [class.active]="'contracts' === routerUrl">{{ 'TranslateContracts' | i18next }}</a>
      </ng-container> -->

      <ng-container *ngIf="!CONSTANTS.allUserRole.includes(user?.role)">
        <a routerLink="legal-entity" [class.active]="'legal-entity' === routerUrl || 'edit' === editUrl">{{ 'Legal Entities' | i18next }}</a>
      </ng-container>

      <hr class="mt-2 mb-2 line w-100" *ngIf="!CONSTANTS.allUserRole.includes(user?.role)" />

      <a routerLink="general-info" [class.active]="'general-info' === routerUrl">{{ 'General Info' | i18next }}</a>

      <a routerLink="about"  [class.active]="'about' === routerUrl">{{ 'About' | i18next }}</a>

      <a routerLink="offices"[class.active]="'offices' === routerUrl">{{ 'Offices' | i18next }}</a>

      <a routerLink="publications" [class.active]="'publications' === routerUrl">{{ 'Publications' | i18next }}</a>

      <a routerLink="offerings"  [class.active]="'offerings' === routerUrl">{{ 'Offerings' | i18next }}</a>

      <a routerLink="case-studies" [class.active]="'case-studies' === routerUrl">{{ 'Case Studies' | i18next }}</a>

      <!-- TODO: we comment this line as per this CN-1283 -->
      <!-- <div class="mb-1" *ngIf="!CONSTANTS.allUserRole.includes(user?.role)">
        <a routerLink="{{ baseUrl }}/billing-address" [class.active]="'billing-address' === routerUrl">{{ 'Billing Address' | i18next }}</a>
      </div> -->
    </div>
  </div>

  <div class="d-flex flex-column w-100 overflow-hidden">
    <div class="bg-white border-radius-6 p-y-12 p-x-20 d-flex align-items-center justify-content-between">
      <h3 class="f-18 f-w-700 txt-primary-01 font-Jakarta">{{ pageTitle[currentPage]?.title | i18next }}</h3>
      <div class="d-flex align-items-center" *ngIf="!CONSTANTS.allUserRole.includes(user?.role)">
        <button
          mat-stroked-button
          color="secondary"
          class="medium"
          *ngIf="'account' === routerUrl"
          [disabled]="!isAdminChangeEnabled"
          [matTooltipDisabled]="isAdminChangeEnabled"
          matTooltip="{{ 'No active users exist' }}"
          (click)="openClientAdminDrawer()"
        >
          Change Admin
        </button>
        <button
          color="secondary"
          class="medium"
          mat-stroked-button
          matTooltip="{{ 'Edit Contract' | i18next }}"
          matTooltipPosition="above"
          *ngIf="'contracts' === routerUrl && isContractEditVisible"
          (click)="openAddEditDrawer(accountTypes.FirmContract)"
        >
          {{ 'Edit' | i18next }}
        </button>
      </div>
      <div class="d-flex align-items-center" *ngIf="!CONSTANTS.allUserRole.includes(user?.role) && tabName === 'accounts' && onBoardingStatus === 'onGoing'">
        <button color="secondary" class="small" mat-stroked-button type="button" (click)="updateBoardingStatus()">
          {{ 'Approve' | i18next}}
        </button>
      </div>
    </div>
    <div
      class="content-section flex-one p-20 bg-white border-radius-6 m-t-12" 
      [ngClass]="{ 'overflow-auto': !['account', 'contracts'].includes(routerUrl), entityscreen: pageTitle[currentPage]?.title === 'Legal Entity', 'p-0': !CONSTANTS.allUserRole.includes(user?.role) && 'account' ===  routerUrl }">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
