import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileCardComponent } from './file-card.component';
import { ConpulseMaterialModule } from '../../material-module';
import { I18NextModule } from 'angular-i18next';



@NgModule({
  declarations: [FileCardComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    I18NextModule
  ],
  exports: [FileCardComponent]
})
export class FileCardModule { }
