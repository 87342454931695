import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, OnInit, output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User } from '@auth0/auth0-angular';
import { CONSTANTS, noDataContent, NotificationService, provideHeader, QuestionnaireItem, UploaderService, UserService, ViewType } from '@conpulse-web/core';
import { I18NextModule } from 'angular-i18next';
import { CommonService, MyCustomPaginatorIntl, UtilityMethodsService } from '../../services';
import { FileCardModule } from '../file-card/file-card.module';
import { NoContentModule } from '../no-content/no-content.module';
import { SpecificQuestionViewDialogComponent } from '../specific-question-view-dialog/specific-question-view-dialog.component';

@Component({
  selector: 'conpulse-web-specific-question-comparison-table',
  standalone: true,
  imports: [
    CommonModule,
    I18NextModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    FileCardModule,
    NoContentModule,
    MatIcon,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
  templateUrl: './specific-question-comparison-table.component.html',
  styleUrl: './specific-question-comparison-table.component.scss',
})
export class SpecificQuestionComparisonTableComponent implements OnInit {
  header = input<provideHeader[]>([]);
  answer = input<QuestionnaireItem[]>([]);
  isLoading = input(false);
  displayedColumns: string[] = [];
  openDrawer = output();
  tab: string;
  user: User;
  isProvider: boolean = false;
  noData: noDataContent = JSON.parse(JSON.stringify(CONSTANTS.NoDataContent));
  dataSource = new MatTableDataSource<QuestionnaireItem>([]);
  viewProposal: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private uploaderService = inject(UploaderService);
  private notificationService = inject(NotificationService);
  public utilityService = inject(UtilityMethodsService);
  readonly dialog = inject(MatDialog);
  private userService = inject(UserService)
  private commonService = inject(CommonService)

  constructor() {
    effect(() => {
      if (this.header().length) {
        this.dataSource.data = this.answer();
        this.dataSource.paginator = this.paginator;
        this.displayedColumns = ['question', ...this.header().map((data) => data._id)];
      }
    });
  }

  ngOnInit(): void {
    this.user = this.userService.currentUserInformation;
    this.isProvider = CONSTANTS.CF_PROFILE_ROLES.includes(this.user.role);
    this.viewProposal = this.commonService.isProposalAnswerVisible.value;
  }

  async downloadDocument({ documentKey, documentName }) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: documentKey, fileName: documentName }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  async viewDocument({ documentKey, documentType }) {
    try {
      if (documentType == 1) {
        window.open(documentKey);
        return;
      }
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey: documentKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  getProfileClass(i: number) {
    const modvalue = i % 4;
    switch (modvalue) {
      case 0:
      default:
        return 'orange';
      case 1:
        return 'blue';
      case 2:
        return 'dark-green';
      case 3:
        return 'red';
    }
  }

  checkTruncation(element: HTMLElement): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  truncateDocumentName(name: string): string {
    if (name.length <= 10) return name;

    const [base, ext = ''] = name.split('.');
    return `${base.slice(0, 5)}...${base.slice(-2)}${ext ? '.' + ext : ''}`;
  }

  isGroup = (index: number, item: any): boolean => {
    return item.hasOwnProperty('section') && !item.hasOwnProperty('question');
  };

  openDialog(question, ans): void {
    const dialogRef = this.dialog.open(SpecificQuestionViewDialogComponent, {
      data: {question, ans},
      disableClose: true,
    });
  }
}
