import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService, CONSTANTS, CompanyService, EnabledModuleInfo, NotificationService, UserService, user } from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash-es';
import {filter } from 'rxjs/operators';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-client-module-management',
  templateUrl: './client-module-management.component.html',
  styleUrls: ['./client-module-management.component.scss'],
})
export class ClientModuleManagementComponent implements OnInit {
  @ViewChild('form', { static: true }) form: NgForm;
  user: user = {} as user;
  isLoading: boolean = false;
  availableModulesList = [];
  // tempList = [];
  enabledModuleInfo: EnabledModuleInfo = {} as EnabledModuleInfo;
  initialEnabledModuleInfo = [];
  routerSubscription: Subscription | null = null;
  isSuperAdmin: boolean = false;
  companyId: string = '';
  defaultModule = ['HOME'];

  constructor(
    private userService: UserService,
    private utilityService: UtilityMethodsService,
    private notificationService: NotificationService,
    private adminService: AdminService,
    private router: Router,
    private companyService: CompanyService,
    private i18nextPipe: I18NextPipe
  ) {
    this.routerSubscription = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url) {
        const route = event.url.split('/');
        this.isSuperAdmin = false;
        if (!CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role)) {
          this.isSuperAdmin = true;
          this.companyId = route[route.length - 3];
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = this.userService.currentUserInformation;
    const route = this.router.url.split('/');
    this.isSuperAdmin = false;
    if (!CONSTANTS.CLIENT_USER_ROLES.includes(this.user?.role)) {
      this.isSuperAdmin = true;
      this.companyId = route[route.length - 3];
    }
    this.getModuleData();
  }

  saveClientModuleDetails() {
    this.isLoading = true;
    const enabledModule = JSON.parse(JSON.stringify(this.availableModulesList));
    enabledModule.forEach((module) => {
      if (module.children.length) {
        module.isBlocked = module.children.every((data) => data.isBlocked);
      }
    });
    const companyId = this.isSuperAdmin ? this.companyId : this.user?.companyId?.['_id'];
    this.companyService.updateClientModule(companyId, enabledModule).subscribe(
      (response) => {
        this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('Options have been updated.', { ns: 'Snackbar' }))
        this.getModuleData();
        this.isLoading = false;
      },
      (error) => this.notificationService.openErrorSnackBar(error?.message || error)
    );
  }

  /**
   * Checks if save button is disabled or not
   * @returns Boolean value indicating whether the button is disabled
   */
  checkButtonDisabled() {
    return isEqual(this.initialEnabledModuleInfo, this.availableModulesList);
  }

  getClass = (index: number) => this.utilityService.getClass(index);

  /**
   * Determines whether a module is enabled/disabled
   * @param moduleId Parent Module Identifier
   * @param childModuleId Child Module Identifier
   */
  isModuleEnabled(moduleId: string, childModuleId?: string) {
    const moduleData = this.availableModulesList.find((module) => module.moduleId === moduleId);
    const child = moduleData.children.find((data) => data.moduleId === childModuleId);
    return !child.isBlocked;
  }

  /**
   * Checks whethre all child modules are enabled
   * @param moduleId Module ID
   * @returns
   */
  checkAllEnabled(moduleId: string) {
    const moduleData = this.availableModulesList.find((module) => module.moduleId === moduleId);
    if (moduleData.children?.length)
      return moduleData.children.every((module) => !module.isBlocked);
    else {
      return !moduleData.isBlocked
    }
  }

  /**
   * Enable/disable module for client
   * @param isEnable Enable/disable flag
   * @param moduleId Module ID
   * @param childModuleId Child Module ID
   */
  enableOrDisableModule(isEnable: boolean, moduleId: string, childModuleId?: string) {
    const moduleData = this.availableModulesList.find((module) => module.moduleId === moduleId);
    if (childModuleId) {
      const child = moduleData.children.find((data) => data.moduleId === childModuleId);
      child.isBlocked = !isEnable;
      moduleData.isBlocked = moduleData.children.every((module) => !module.isBlocked);
    } else {
      moduleData.children.forEach((data) => (data.isBlocked = !isEnable));
      moduleData.isBlocked = !isEnable;
    }
  }

  openClientAdminDrawer() {
    this.adminService.setAccountDrawerState({
      open: true,
      type: 'change-admin',
    });
  }

  checkIndeterminate(moduleId: string) {
    const moduleData = this.availableModulesList.find((module) => module.moduleId === moduleId);
    if (!moduleData || !moduleData?.children?.length) return false;
    const length = moduleData?.children?.filter((module) => !module.isBlocked).length;
    return length > 0 && moduleData?.children?.length !== length;
  }

  getModuleData() {
    this.isLoading = true;
    this.companyService.getClientModule(this.isSuperAdmin ? this.companyId : this.user?.companyId?.['_id']).subscribe(
      (data) => {
      this.availableModulesList = data.data;
      this.availableModulesList.sort((a, b) => a.order - b.order);
      this.initialEnabledModuleInfo = JSON.parse(JSON.stringify(this.availableModulesList));
      this.isLoading = false;
    },
    (error) => {
      this.isLoading = false;
      this.notificationService.openErrorSnackBar(error?.message || error)
    }
  );
  }
}
