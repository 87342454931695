<ng-container *ngIf="!viewOnly">
  <label class="f-14">{{ 'Document upload' | i18next }}</label>
  <div class="upload-button-wrapper">
    <button
      type="button"
      class="upload-button"
      [disabled]="(documents[0]?.key || documents[0]?.name || documents[0]?.file) && !multipleUpload"
      mat-flat-button
      color="secondary"
      (click)="fileUpload.click()"
    >
      <i class="material-icons">upload</i>
      {{ 'Upload' | i18next }}
    </button>
    <input
      stripExif
      hidden="true"
      [multiple]="multipleUpload"
      accept="{{ documentDetails.allowedFiles }}"
      type="file"
      #fileUpload
      (click)="fileUpload.value = null"
      [disabled]="(documents[0]?.key || documents[0]?.name || documents[0]?.file) && !multipleUpload"
      value=""
      id="fileRef"
      name="{{ 'DocumentUpload' }}"
      (input)="uploadDocument($event)"
    />
  </div>
  <div class="py-2 mt-2">
    <p class="bold f-14">{{ 'OR' | i18next }}</p>
  </div>
</ng-container>
<div class="mt-2">
  <div *ngIf="!viewOnly">
    <div class="mx-0 mb-3">
        <p class="f-14">{{ ('Drive link' | i18next) }}</p>
    </div>
    <div class="mx-0">
      <form #myForm="ngForm">
        <div class="p-0">
          <div class="w-50">
            <mat-form-field
              class="full-width"
              subscriptSizing="dynamic"
            >
              <input
                matInput
                [(ngModel)]="driveLink"
                [disabled]="(documents[0]?.key || documents[0]?.name || documents[0]?.file) && !multipleUpload"
                [pattern]="websiteRegex"
                placeholder="{{ 'Paste drive link here' | i18next }}"
                #newDriveLink="ngModel"
                name="{{'driveLink'}}"
                (keyup.enter)="driveLink?.length && newDriveLink.valid ? addLinkToDocument() : ''"
              />
              <span matSuffix class="add">
                <button type="button" mat-raised-button color="primary" [disabled]="driveLink?.length < 1 || !newDriveLink.valid || ((documents[0]?.key || documents[0]?.name || documents[0]?.file) && !multipleUpload)" (click)="addLinkToDocument()">
                  <i class="material-icons add-icon">add</i>{{ 'Add' | i18next }}
                </button></span
              >
            </mat-form-field>
            <div class="mt-2">
              <mat-error *ngIf="newDriveLink.hasError('pattern')">{{ 'Invalid Drive Link' | i18next }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngFor="let document of documents; let i = index">
      <div class="ml-1 uploaded-file" [ngClass]="{ 'skeleton-loader w-100 skeleton-title': isLoading}">
        <ng-container *ngIf="!isLoading && (document?.key || document?.name)">
          <div
            class="d-flex mt-2 align-items-center mr-2 px-3 py-2 document-container" [ngClass]="{ 'w-100': viewOnly,'w-50': !viewOnly}"
          >
            <div class="d-flex flex-1 align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="mr-2 file-upload-size" *ngIf="document.type === documentType.DOCUMENT; else linkIconRef">
                  <img class="w-100" [src]="utilityService.getAssetsPath(document.name)" />
                </div>
                <ng-template #linkIconRef>
                  <div class="mt-2 mr-2">
                    <i class="material-icons text-dark-green">link</i>
                  </div>
                </ng-template>
                <p class="title-primary text-truncate" [ngClass]="{'mw-30vw': !viewOnly, 'mw-40vw': viewOnly }"><ng-container *ngIf="document.type !== documentType.DOCUMENT && viewOnly; else staticText"><a class="text-dark" [href]="getLinkToOpen(document.key)" target="_blank">{{document.key}}</a></ng-container><ng-template #staticText>{{ document.type ===  documentType.DOCUMENT ? document?.name : document?.key }}</ng-template></p>
                <div class="p-2" *ngIf="viewOnly && document.type === documentType.DOCUMENT && document?.key"  matTooltip="{{ document?.name ? ('Download' | i18next) : null }}"
                matTooltipPosition="below"
                (click)="downloadDocument(document?.key, document?.name)">
                  <i class="material-icons icon pointer f-20 l-h-28">download</i>
                </div>
              </div>
              <div class="d-flex align-items-center" *ngIf="!viewOnly">
                <span *ngIf="!document?.isNewUpload" matTooltip="{{ document?.name ? ('View Document' | i18next) : null }}"
                  matTooltipPosition="above"
                  (click)="document.type ===  documentType.DOCUMENT ? downloadDocument(document?.key) : openLinkInNewWindow(document.key)"><i
                    class="material-icons m-2 icon pointer">visibility</i></span>
                <span (click)="resetDocumentDetails(i)">
                    <i class="material-icons m-2 icon pointer">close</i>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
  </ng-container>
</div>
