import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import {
  ASSETS,
  AccountDetails,
  AccountUsage,
  AdminService,
  CONSTANTS,
  ClientAccountTypes,
  NotificationService,
  OnboardingStatus,
  RenewalTypeOptions,
  TimePeriodOptions,
  UploaderService,
  UserService,
  user,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '@conpulse-web/conpulse';
import { Subscription } from 'rxjs';
import { get, isEmpty } from 'lodash-es';
import { filter, map } from 'rxjs/operators';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-client-account',
  templateUrl: './client-account.component.html',
  styleUrls: ['./client-account.component.scss'],
})
export class ClientAccountComponent implements OnInit, OnDestroy {
  date = new Date();
  routerSubscription: Subscription | null = null;
  companyId: string;
  isSuperAdmin: boolean = false;
  user: user = {} as user;
  isRecordExist: boolean = false;
  accountDetails: AccountDetails = {} as AccountDetails;
  clientAccountUsage: AccountUsage = {} as AccountUsage;
  accountTypes = ClientAccountTypes;
  clientAdminDetails: { name: string; _id: string; email: string } = {} as { name: string; _id: string; email: string };
  clientCreatedAt: Date;
  periodOptions = TimePeriodOptions;
  renewalOptions = RenewalTypeOptions;
  isEmpty = isEmpty;
  isLoading: boolean = false;
  isCompanyLoading: boolean = false;
  isClient: boolean = false;
  notFoundImage: string;
  onboardingStatus: string;
  initialOnboardingStatus: string;
  OnboardingStatusType = OnboardingStatus;
  allTabs = [ {
    label: 'Platform',
    url: 'platform'},
    {label: 'Marketplace', url: 'marketplace'},
    {label: 'Managed Services', url: 'managed-services'},
  ];
  activeTabs = [];
  constructor(
    private adminService: AdminService,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    private utilityService: UtilityMethodsService,
    private readonly uploaderService: UploaderService,
    private i18nextPipe: I18NextPipe
  ) {}

  ngOnInit(): void {
    const params = this.utilityService.collectAllRouteParams(this.router);
    this.user = this.userService.currentUserInformation;
    this.notFoundImage = `${this.userService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
    const route = this.router.url.split('/');
    if (![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.user?.role) || !this.userService.currentUserInformation?.role) {
      this.isSuperAdmin = true;
      this.isClient = route.includes('client');
      this.companyId = params?.id;
    }
    this.routerSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd || (event instanceof Scroll && event.routerEvent instanceof NavigationEnd)),
      map((event) => {
        if (event instanceof Scroll) {
          return event.routerEvent;
        }
        return event;
      })
    ).subscribe((event: NavigationEnd) => {
      const params = this.utilityService.collectAllRouteParams(this.router);
      if (event.url) {
        this.isClient = CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role);
        this.adminService.resetAccountDrawer();
        const route = event.url.split('/');
        if (
          ![...CONSTANTS.CLIENT_USER_ROLES, ...CONSTANTS.CF_PROFILE_ROLES].includes(this.userService.currentUserInformation?.role) ||
          !this.userService.currentUserInformation?.role
        ) {
          this.isSuperAdmin = true;
          this.isClient = route.includes('client');
          this.companyId = params?.id;
        }
        this.activeTabs = !this.isClient ? this.allTabs.filter(tab => tab.url !== 'managed-services') : this.allTabs;
        this.getAccountDetails();
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  getAccountDetails() {
    this.isLoading = true;
    this.adminService.getAccountDetailsForClients(
        this.isSuperAdmin ? this.companyId : this.isClient ? this.user?.companyId?.['_id'] : this.user?.firmId?.['_id'],
        this.isClient
    ).subscribe({
      next: (response) => {
        this.accountDetails = response?.data; // this.isLoading = false;
        this.onboardingStatus = this.isClient ? response.data.clientId?.onboardingStatus : response.data.onboardingStatus;
        this.initialOnboardingStatus = response.data.onboardingStatus;
        this.clientAccountUsage = response?.data?.accountUsage || {};
        if (this.accountDetails?.administrativeInfo?.adminContact?._id && !this.isClient) {
          this.clientAdminDetails = JSON.parse(JSON.stringify({ ...this.accountDetails.administrativeInfo.adminContact }));
        }
        this.isLoading = false;
      },
      error: (error) => {
        if (error.code === 404) {
          this.isRecordExist = true;
        } else {
          this.isRecordExist = false;
          this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to get details'));
        }
        this.isLoading = false;
      },
    });
  }

  openAddEditDrawer(type: string) {
    this.adminService.setAccountDrawerState({
      open: true,
      type: type,
      accountDetails: this.accountDetails,
      isClient: this.isClient,
    });
  }

  async downloadDocument(documentKey: string) {
    try {
      const response = await this.uploaderService.getFileViewUrl({ folderPath: 'admin/contract', fileKey: documentKey }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  openClientAdminDrawer() {
    this.adminService.setAccountDrawerState({
      open: true,
      type: 'change-admin',
    });
  }

  notifySuperAdmin() {
    this.adminService.notifySuperAdmin().subscribe((data) => {
      this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform(`Successfully notified Consource`, { ns: 'Snackbar' }));  
    }, (error) => {
      this.notificationService.openErrorSnackBar(`Unable to notify Consource`);
    })
  }
}
