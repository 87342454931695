<div class="bg-white p-12 text-center border-radius-6 h-100">
    <div class="d-flex align-items-center justify-content-center flex-column h-100">
        <img class="vector-image" [src]="notFoundImage" alt="no data image" />
        <p class="nodata-title font-Jakarta">{{data?.title | i18next }}</p>
        <p class="nodata-summary m-t-12">
            {{ (!data?.search ? data?.content : data?.content.replace("to display", "for the keyword") )| i18next }}
            <span *ngIf="data?.search">{{data?.search}}</span>
        </p>
        <button *ngIf="(data.isActionNeed && !isProjectPaused && !isProjectCancelled)" class="m-t-10" mat-stroked-button (click)="addEmitter.emit()"
            color="secondary"><mat-icon *ngIf="data?.isIconNeed" class="mat-20 mr-2">add</mat-icon>{{data?.btnName | i18next}}</button>
    </div>
</div>