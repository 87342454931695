import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { get } from 'lodash-es';
import { catchError, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UserService } from '../apis/index';

@Injectable()
export class AccountGuard {
  constructor(private router: Router, private userService: UserService, @Inject(DOCUMENT) private document: any) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.userService.checkToken(`validateLogin`).pipe(
      map((response) => {
        if (response.isAdmin) {
          if (get(response, 'user.firmId.progressCounter', '') > 4 || get(response, 'user.companyId.progressCounter', '') > 4) {
            this.document.location.href = response.isClient
              ? this.document.location.origin + '/client/dashboard'
              : this.document.location.origin + '/user/dashboard';
            return false;
          }
          return true;
        } else {
          this.document.location.href = this.document.location.origin + '/signin';
          return false;
        }
      }),
      catchError((err) => {
        this.document.location.href = this.document.location.origin + '/signin';
        return of(false);
      })
    );
  }
}
