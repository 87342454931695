import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConpulseModule } from 'libs/conpulse/src/index';
import { I18NextModule } from 'angular-i18next';
import { SelectConsultingfirmComponent } from './select-consultingfirm.component';
import { HelpCenterModule } from '../help-center/help-center.module';
import { SupportContactModule } from '../support-contact/support-contact.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [SelectConsultingfirmComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18NextModule,
    ConpulseModule,
    HelpCenterModule,
    SupportContactModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule
  ],
  exports: [SelectConsultingfirmComponent],
})
export class SelectConsultingfirmModule {}
