<!-- organizational unit section html here -->
<div class="demographics-model border-radius-6 overflow-hidden">
  <div class="d-flex align-items-center p-20">
    <mat-icon class="material-icons pointer f-20 txt-shuttle-grey d-flex justify-content-center align-items-center"
      (click)="navigateBack()">arrow_back</mat-icon>
    <!-- <h4 class="heading ml-2">{{ 'Back' | i18next }}</h4> -->
    <h4 class="tab-inside-title font-Jakarta ml-2"
      [ngClass]="{ 'skeleton-loader skeleton-title w-200px h-24': isLoading }">
      {{ editorTitle  }}
    </h4>
  </div>
  <form #editorForm="ngForm">
    <div class="account-setup p-20">
      <div class="account-step-2">
        <div class="template-editor mb-2 w-100">
          <p class="mb-16 tab-inside-title font-Jakarta"
            [ngClass]="{ 'skeleton-loader skeleton-title w-200px h-24': isLoading }">
            {{ 'Basic Details' | i18next }}
          </p>
          <div class="template-editor-name mb-3 display-grid section-fields">
            <div>
              <label [ngClass]="{ 'skeleton-loader skeleton-title w-100px h-24': isLoading }">{{ 'Template Name' |
                i18next }}</label>
              <mat-form-field class="full-width mr-2" subscriptSizing="dynamic"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                <input matInput conpulseWebSanitizeHtml placeholder="{{ 'Enter Template Name' | i18next }}"
                  [(ngModel)]="templateData.templateName" name="templateName" (input)="validateChanges()"
                  [required]="true" [disabled]="isSaving" #TemplateName="ngModel"
                  (blur)="templateData.templateName = templateData.templateName.trim()" />
                <mat-error *ngIf="TemplateName.hasError('required')">{{ 'Template Name is required' | i18next
                  }}</mat-error>
              </mat-form-field>
            </div>
            <div>
              <label [ngClass]="{ 'skeleton-loader skeleton-title w-100px h-24': isLoading }">{{ 'Template Language' |
                i18next }}</label>
              <mat-form-field class="full-width" subscriptSizing="dynamic"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                <mat-select class="h-40" placeholder="{{ 'Select Language' | i18next }}"
                  [(ngModel)]="templateData.language" (selectionChange)="validateChanges()" name="templateLanguage"
                  [required]="true" #TemplateLanguage="ngModel">
                  <mat-option *ngFor="let language of languageList" [value]="language._id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="TemplateLanguage.hasError('required')">{{ 'Template Language is required' | i18next
                  }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="border-top m-b-24"></div>
          <div class="template-editor-section mb-4" *ngFor="let section of templateSpec; let sectionIndex = index">
            <div *ngIf="section?.isStartNumber" class="border-top m-b-24"></div>
            <h5 class="tab-inside-title font-Jakarta mb-4"
              [ngClass]="{ 'skeleton-loader skeleton-title w-200px h-24': isLoading }"
              *ngIf="templateCategory === 'Template'">
              {{ !section?.isStartNumber ? (sectionIndex + 1 + '.') : '' }} {{ section?.sectionName | i18next }}
            </h5>
            <div>
              @if(section.sectionKey === 'specificQuestions') {
                <h6 class="tab-inside-subtitle mb-2" [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                 {{ 'Guidelines' | i18next }}
               </h6>
                <div class="table-note-container">
                  <p class="note-title">{{ 'Note' | i18next }}</p>
                  <div class="m-t-12">
                    <p class="note-info">
                      {{ 'The Specific Questions for the project should be entered here' | i18next }}
                    </p>
                  </div>
                </div>  
              }
              </div>
            <ng-container [ngSwitch]="section.type">
              <ng-container *ngSwitchCase="'dynamic'">
                <div class="section-fields mb-3">
                  <ng-container *ngIf="section.sectionName === 'Appendix'; else otherDynamicFieldsRef">
                    <!-- Appendix sections -->
                    <conpulse-web-dynamic-sections [sections]="dynamicConstants[section.sectionName]"
                      [superSectionIndex]="sectionIndex" [tabName]="section.sectionName"
                      (onAddAppendixSections)="onAddDynamicSections($event, section.sectionName)"
                      (onChangeSectionTitle)="onSectionTitleChange($event, section.sectionName)"
                      (isValidSection)="updateSectionValidity($event)"></conpulse-web-dynamic-sections>
                  </ng-container>
                  <ng-template #otherDynamicFieldsRef>
                    <conpulse-web-customisation-dynamic-template
                      [dynamicFormLists]="dynamicConstants[section.sectionName]" [viewList]="viewer"
                      [tabName]="section.sectionName" [OnEditDynamicData]="specificQuestion"
                      (onAddGeneralProvisions)="onAddDynamicFields($event, section.sectionName)"></conpulse-web-customisation-dynamic-template>
                  </ng-template>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'populatedInProject'">
                <div class="table-note-container">
                  <p class="note-title">{{ 'Note' | i18next }}</p>
                  <div class="m-t-12">
                    <p class="note-info">
                      {{ section?.placeHolder || 'This information is project specific and is edited directly in the
                      project wizard.' | i18next }}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <!-- Template static fields -->
                <div class="section-fields mb-3" *ngIf="templateCategory === categoryType.TEMPLATE">
                  <div class="template-editor-field quill-bordered mb-32"
                    *ngFor="let field of section.fields; let fieldIndex = index; let isLast = last"
                    [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h6 class="tab-inside-subtitle mb-2"
                          [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }"
                          *ngIf="templateCategory === 'Template'; else layoutTitleRef">
                          <span *ngIf="field.fieldName && !section?.isStartNumber; else showChangeOrderFelid">{{
                            sectionIndex + 1 + '.' }}{{ fieldIndex + 1 + '.' }} {{ field.fieldName | i18next }}</span>
                          <ng-template #showChangeOrderFelid>
                            <span *ngIf="field.fieldName && section?.isStartNumber">{{ fieldIndex + 1 + '.' }} {{
                              field.fieldName | i18next }}</span>
                          </ng-template>
                        </h6>
                      </div>
                      <div>
                        <mat-slide-toggle
                          *ngIf="isToggle && templateCategory != categoryType.LAYOUT && field.fieldKey !== 'table'"
                          color="secondary"
                          [matTooltip]="get(templateModel[section.sectionKey], field.fieldKey, '').isActive ? ('Deactivate' | i18next) : ('Activate' | i18next)"
                          [checked]="get(templateModel[section.sectionKey], field.fieldKey, '').isActive"
                          (change)="updateToggleValue(section.sectionKey, field.fieldKey, $event.checked)"></mat-slide-toggle>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="field?.fieldKey !== 'table' && field.fieldKey !== 'populatedInProject' && field.fieldKey !== 'subSection'; else tableRef">
                      <div class="quill-height-200"
                        *ngIf="isToggle && templateCategory != categoryType.LAYOUT; else notRfpLayout">
                        <editor  [init]="initEditor(field.placeHolder | i18next)" [(ngModel)]="get(templateModel[section.sectionKey], field.fieldKey, '').value" name="template-{{ field.fieldKey }}"> </editor>

                      </div>
                      <ng-template #notRfpLayout>
                        <div class="quill-height-200">
                          <editor *ngIf="!isLoading"  [init]="initEditor(field.placeHolder | i18next)" [(ngModel)]="templateModel[section.sectionKey][field.fieldKey]" name="template-{{ field.fieldKey }}"> </editor>
                        </div>
                      </ng-template>
                    </ng-container>
                    <ng-template #tableRef>
                      <ng-container *ngIf="field.fieldKey === 'subSection'; else populatedRef">
                        <ng-container *ngFor="let subSection of field.subsection; let subIndex = index">
                          <h6 class="tab-inside-sub-section-title mb-2"
                            [ngClass]="{ 'skeleton-loader skeleton-title w-200px h-24': isLoading, 'mt-3': subIndex === 0 }">
                            {{ sectionIndex + 1 + '.' }}{{ fieldIndex + 1 + '.' }}{{subIndex+1 +'.'}} {{
                            subSection?.fieldName | i18next }}
                          </h6>
                          <div class="table-note-container mb-32">
                            <p class="note-title">{{ 'Note' | i18next }}</p>
                            <div class="m-t-12">
                              <p class="note-info">
                                {{ section?.placeHolder || 'This information is project specific and is edited directly
                                in the project wizard.' | i18next }}
                              </p>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-template #populatedRef>
                        <div class="table-note-container">
                          <p class="note-title">{{ 'Note' | i18next }}</p>
                          <div class="m-t-12">
                            <p class="note-info">
                              {{ field?.placeHolder || 'This information is project specific and is edited directly in
                              the project wizard.' | i18next }}
                            </p>
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
                <!-- Layout static fields -->
                <ng-container *ngIf="templateCategory === categoryType.LAYOUT">
                  <!-- quill -->
                  <div class="m-b-24">
                    <p class="font-Jakarta tab-inside-title mb-20">{{ 'Page Style' | i18next }}</p>
                    <div class="section-fields mb-5">
                      <div class="template-editor-field quill-bordered m-b-24 d-flex"
                        *ngFor="let field of section.fields; let fieldIndex = index; let isLast = last"
                        [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                        <!-- hint -->
                        <div>
                          <div class="poniter" matTooltipPosition="above"
                            [matTooltip]="'Cover Page Content, gives highlight about of the contract and it is place as first page of the document' | i18next"
                            *ngIf="field.fieldKey === 'coverPageContent'">
                            <img src="assets/images/cover-hint.png" alt="asc" />
                          </div>
                          <div class="poniter" matTooltipPosition="above"
                            [matTooltip]="'Header Content, is placed on top of the main content on each page of the document' | i18next"
                            *ngIf="field.fieldKey === 'headerContent'">
                            <img src="assets/images/header-hint.png" alt="asc" />
                          </div>
                          <div class="poniter" matTooltipPosition="above"
                            [matTooltip]="'Footer Content, is placed on the bottom of the main content on each page of the document' | i18next"
                            *ngIf="field.fieldKey === 'footerContent'">
                            <img src="assets/images/footer-hint.png" alt="asc" />
                          </div>
                        </div>
                        <div class="w-100">
                          <!-- content -->
                          <div class="ml-26">
                            <p class="f-13 f-w-700 mb-12"
                              [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                              {{ field.fieldName | i18next }}
                            </p>
                          </div>
                          <div class="ml-20" quillToggleShow>
                            <div class="quill-text text pl-2">
                              <div class="template-editor-heading f-15 f-w-400 txt-aluminium-grey" [innerHTML]="
                                  (templateModel[section.sectionKey][field.fieldKey]
                                    ? templateModel[section.sectionKey][field.fieldKey]
                                    : (('Enter' | i18next) + ' ' + field.fieldName | i18next)
                                  ) | safe: 'html'
                                "></div>
                            </div>
                            <div class="quill-editor-display-status">
                              <div class="quill-height-200">
                                @let placeholder = (('Enter' | i18next) + ' ' + field.fieldName | i18next) + ' ' + ('details' | i18next);
                                <editor  [init]="initEditor(placeholder)" [(ngModel)]="templateModel[section.sectionKey][field.fieldKey]" name="template-{{ field.fieldKey }}"> </editor>
                                <!-- <quill-editor *ngIf="!isLoading" [disabled]="isSaving"
                                  (onContentChanged)="validateChanges()" (onBlur)="
                                    templateModel[section.sectionKey][field.fieldKey] = trimQuillValue(templateModel[section.sectionKey][field.fieldKey])
                                  " [(ngModel)]="templateModel[section.sectionKey][field.fieldKey]"
                                  name="template-{{ field.fieldKey }}"
                                  placeholder="{{ 'Enter' | i18next }} {{ field.fieldName }}"
                                  [modules]="RfpSettingsQuillConfig"></quill-editor> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="border-top m-b-24"></div>

                  <ng-container *ngFor="let section of styleSection; let isLast = last">
                    <div class="m-b-24">
                      <p class="font-Jakarta tab-inside-title mb-20">{{ section.sectionName | i18next }}</p>
                      <div class="template-editor-field mb-4 quill-bordered"
                        *ngFor="let field of section.fields; let styleIndex = index"
                        [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                        <div class="d-flex align-items-center justify-content-between w-100">
                          <div class="d-flex align-items-center mb-18">
                            <span
                              class="alphabet mr-2 f-w-600 f-13 d-flex align-items-center justify-content-center border-radius-full">
                              {{ field.icon }}
                            </span>
                            <p class="f-w-600 f-13 txt-primary-01"
                              [ngClass]="{ 'skeleton-loader skeleton-title h-100px w-100 h-auto': isLoading }">
                              {{ field.fieldName | i18next }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <ng-container *ngIf="section.sectionKey === 'textStyle'">
                              <div class="template-font-family w-150px mr-12">
                                <mat-form-field class="full-width" subscriptSizing="dynamic"
                                  [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                                  <mat-select required [(ngModel)]="styleModel[field.fieldKey]['fontFamily']"
                                    name="fontFamily-{{ field.fieldKey }}" placeholder="{{ 'Font' | i18next }}"
                                    (selectionChange)="constructStyle(field.fieldKey)" #FontFace="ngModel">
                                    <mat-option *ngFor="let font of fontFamily" [value]="font.value"> {{ font.label
                                      }}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="FontFace.hasError('required')">{{ 'Font is required' | i18next
                                    }}</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="template-font-size w-150px mr-12">
                                <mat-form-field class="full-width" subscriptSizing="dynamic"
                                  [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                                  <mat-select required [(ngModel)]="styleModel[field.fieldKey]['fontSize']"
                                    name="fontSize-{{ field.fieldKey }}" placeholder="{{ 'Font Size' | i18next }}"
                                    (selectionChange)="constructStyle(field.fieldKey)" #FontSize="ngModel">
                                    <mat-option *ngFor="let size of fontSize" [value]="size.value"> {{ size.label
                                      }}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="FontSize.hasError('required')">{{ 'Font size is required' | i18next
                                    }}</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="template-font-style w-150px mr-12">
                                <mat-form-field class="full-width" subscriptSizing="dynamic"
                                  [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': isLoading }">
                                  <mat-select [(ngModel)]="styleModel[field.fieldKey]['fontStyle']"
                                    name="fontStyle-{{ field.fieldKey }}" placeholder="{{ 'Font Style' | i18next }}"
                                    (selectionChange)="constructStyle(field.fieldKey)" #FontStyle="ngModel" multiple>
                                    <mat-option *ngFor="let style of fontStyle" [value]="style.value"> {{ style.label
                                      }}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="FontStyle.hasError('required')">{{ 'Style is required' | i18next
                                    }}</mat-error>
                                </mat-form-field>
                              </div>
                            </ng-container>
                            <div>
                              <div class="pointer d-flex align-items-center color-button w-fit border-radius-6"
                                [matMenuTriggerFor]="menu" (click)="updateMenuToggle(field.fieldKey)">
                                <p class="">{{ styleModel[field.fieldKey]['color'] ? styleModel[field.fieldKey]['color']
                                  : '#00000' }}</p>
                                <div class="color-box ml-8"
                                  [style.background-color]="styleModel[field.fieldKey]['color']"></div>
                              </div>
                              <mat-menu #menu="matMenu">
                                <div class="color-menu p-2 d-flex flex-wrap justify-content-between border-radius-6"
                                  [hidden]="colorMenuToggle[field.fieldKey]">
                                  <div *ngFor="let color of colorCodes; let colorIndex = index">
                                    <div class="color-box mb-2 poniter"
                                      [ngClass]="(colorIndex + 1) % 7 === 0 ? 'mr-0' : 'mr-2'"
                                      (click)="selectColor(field.fieldKey, color)" [style.background-color]="color">
                                    </div>
                                  </div>
                                </div>
                              </mat-menu>
                            </div>
                          </div>
                        </div>
                        <div class="layout-item">
                          <div class="layout-text align-items-center d-flex h-100px">
                            <p [style]="preview[field.fieldKey]"
                              *ngIf="section.sectionKey === 'textStyle'; else marginRef">{{
                              defaultStyleText[field.fieldKey] | i18next }}</p>
                            <ng-template #marginRef>
                              <div class="margin-layout" [style.background-color]="styleModel[field.fieldKey]['color']">
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-top m-b-24" *ngIf="!isLast"></div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border-top d-flex align-items-center justify-content-end pl-3 p-r-72 py-3">
      <div class="d-flex mr-4 align-items-center" *ngIf="isSaving">
        <mat-spinner color="primary" [diameter]="loaderDiameter" [strokeWidth]="loaderWidth"></mat-spinner>
        <label class="m-0 ml-2">{{ templateData?._id ? 'Updating...' : 'Saving...' }}</label>
      </div>
      <button class="mr-2" mat-stroked-button color="accent" (click)="exitEditor()" [disabled]="isSaveDisabled()">
        {{ 'Cancel' | i18next }}
      </button>
      <button class="upload-button" mat-flat-button color="primary"
        (click)="templateType === 'msa' && templateCategory === 'Template' || templateType === 'sow' && templateCategory === 'Template' ? uploadDocument() : saveTemplateData()"
        [disabled]="editorForm.invalid || isSaveDisabled()">
        {{ templateData?._id ? ('Update & Exit' | i18next) : ('Save & Exit' | i18next) }}
      </button>
    </div>
  </form>
</div>
