import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of, Observable, from } from 'rxjs';
import { UserService } from '../apis/index';
import { AuthService } from '@auth0/auth0-angular';
import { NotificationService } from '../notification';
import { CONSTANTS } from '../constants';
import { get, includes, isEmpty } from 'lodash-es';

@Injectable()
export class LoginGuard {
  constructor(
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private document: any
  ) {}

  redirectTo(path: string) {
    this.document.location.href = this.document.location.origin + path;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // check whether the user is blocked:
    let error = get(next.queryParams, 'error', '');
    if (error) {
      let errorDescription = get(next.queryParams, 'error_description', '');
      if (errorDescription === 'user is blocked') {
        this.notificationService.openErrorSnackBar('Your Account has been deactivated. Contact your Admin.');
      } else {
        this.notificationService.openErrorSnackBar(errorDescription);
      }
    }

    return from(this.auth.idTokenClaims$).pipe(
      switchMap((claims) => {
        if (claims) {
          return this.userService.checkToken(`validateLogin`).pipe(
            map((data) => {
              const returnUrl = localStorage.getItem('returnUrl');
              const isSuperAdmin = get(data, 'isSuperAdmin', false);
              const isVerified = get(data, 'user.isVerified', false);
              const userRole = get(data, 'user.role', -1);
              const isInvitedFirm = get(data, 'isInvitedFirm', false);
              const isFreeTrial = get(data, 'isFreeTrial', false);
              const isClient = get(data, 'isClient', false);

              if (isSuperAdmin) {
                this.redirectTo(returnUrl ? `/admin${returnUrl}` : '/admin/dashboard');
              } else if (!isVerified) {
                if (userRole === CONSTANTS.role.clientAdmin && !isEmpty(get(data, 'user.companyId', ''))) {
                  this.router.navigate(['/create-sandbox']);
                } else {
                  this.router.navigate(['/notverified']);
                }
              } else if (isInvitedFirm && includes(CONSTANTS.CF_PROFILE_ROLES, userRole)) {
                this.redirectTo(returnUrl ? `/client${returnUrl}` : '/client/invitation-dashboard');
                this.router.navigate(['/account/step1']);
              } else if (isEmpty(get(data, 'user.firmId', '')) && isEmpty(get(data, 'user.companyId', '')) && isFreeTrial) {
                this.router.navigate(['/create-sandbox']);
              } else {
                if (!isFreeTrial) {
                  this.redirectTo(returnUrl ? `/client${returnUrl}` : isClient ? '/client/dashboard' : '/client/invitation-dashboard');
                } else {
                  this.redirectTo(returnUrl ? `/client${returnUrl}` : isClient ? '/client/dashboard' : '/client/invitation-dashboard');
                }
              }
              return true;
            }),
            catchError(() => of(true))
          );
        } else {
          const rootUrlSegment = next?.url?.[0]?.path;
          if (!next.queryParams?.returnUrl && localStorage.getItem('returnUrl')) {
            localStorage.removeItem('returnUrl');
          }
          if (rootUrlSegment === 'signup') {
            this.auth.loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } });
          } else {
            this.auth.loginWithRedirect();
          }
          return of(true);
        }
      }),
      catchError(() => of(true))
    );
  }
}
