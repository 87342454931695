
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[stripExif]'
})
export class StripExifDirective {
  @Output() filesSelected = new EventEmitter<File[]>();
  @Output() fileSelected = new EventEmitter<File>();
  @Output() error = new EventEmitter<string>();

  private EXIF_SUPPORTED_IMAGE_EXTENSION = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp'
  ];

  constructor(private el: ElementRef) {}

  @HostListener('change', ['$event'])
  async onChange(event: Event): Promise<void> {
    const element = event.target as HTMLInputElement;
    const files = element.files;

    if (!files || !files.length) {
      return;
    }

    const processedFiles: File[] = [];
    const errors: string[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        const cleanFile = await this.getCleanImage(file);
        processedFiles.push(cleanFile);
      } catch (error) {
        errors.push(`Error processing ${file.name}: ${error instanceof Error ? error.message : String(error)}`);
      }
    }

    if (errors.length > 0) {
      this.error.emit(errors.join('\n'));
    }

    this.filesSelected.emit(processedFiles);
  }

  private async getCleanImage(imageFile: File): Promise<File> {
    if (this.EXIF_SUPPORTED_IMAGE_EXTENSION.includes(imageFile.type)) {
      const cleanFile: File = await this.removeExifFromImage(imageFile);
      return cleanFile;
    }
    return imageFile;
  }

  private removeExifFromImage(imageFile: File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const reader: FileReader = new FileReader();

      reader.onload = function(event: ProgressEvent<FileReader>): void {
        const img: HTMLImageElement = new Image();

        img.onload = function(): void {
          const canvas: HTMLCanvasElement = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }
          ctx.drawImage(img, 0, 0);

          canvas.toBlob(function(blob: Blob | null): void {
            if (!blob) {
              reject(new Error('Failed to create blob from canvas'));
              return;
            }

            const cleanFile: File = new File([blob], imageFile.name, {
              type: imageFile.type,
              lastModified: new Date().getTime()
            });

            resolve(cleanFile);
          }, imageFile.type);
        };

        img.onerror = function(): void {
          reject(new Error('Failed to load image'));
        };

        if (event.target?.result) {
          img.src = event.target.result as string;
        } else {
          reject(new Error('Failed to read file data'));
        }
      };

      reader.onerror = function(): void {
        reject(new Error('Failed to read file'));
      };

      reader.readAsDataURL(imageFile);
    });
  }
}
