import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';
import { HelpCenterComponent } from './help-center.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [HelpCenterComponent],
  imports: [CommonModule, FormsModule, I18NextModule, MatButtonModule, MatIconModule, MatFormFieldModule],
  exports: [HelpCenterComponent],
})
export class HelpCenterModule {}
