import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonService, TinyMceService, UtilityMethodsService } from '../../services';
import { isEqual } from 'lodash-es';
import {
  ASSETS,
  ConfigService,
  DynamicFieldDataTypes,
  FieldKeyBasedOnType,
  MsaDynamicFieldFilePath,
  NotificationService,
  SoWFieldValidationData,
  SowDynamicFormsValue,
  UploaderService,
  ViewType,
} from '@conpulse-web/core';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-common-appendix',
  templateUrl: './common-appendix.component.html',
  styleUrls: ['./common-appendix.component.scss'],
})
export class CommonAppendixComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean = false;
  @Input() tabNumber: number = 0;
  @Input() commentCountList = [];
  @Input() sections = [];
  @Input() initalSections = [];
  @Input() isInvitedFirm: boolean = false;
  @Input() isEditAccess: boolean = false;
  @Input() tabName: string = '';
  @Input() isFromSow: boolean = false;
  @Input() filePath: string = '';
  @Output() dropSection = new EventEmitter();
  @Output() dropSubSection = new EventEmitter();
  @Output() deleteSection = new EventEmitter();
  @Output() changeActivationStatus = new EventEmitter();
  @Output() saveSectionDetails = new EventEmitter();
  @Output() openAppendixDrawer = new EventEmitter();
  @Output() openComments = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() validateField = new EventEmitter();

  isSectionDragStarted: boolean = false;
  isSubSectionDragStarted: boolean = false;
  sectionDragPlaceholderHeight: number = 60;
  questionDragPlaceholderHeight: number = 60;
  getDynamicCommentCount = this.commonService.getDynamicCommentCount;
  trimQuillValue = this.commonService.trimQuillValue
  dynamicFieldDataTypes = DynamicFieldDataTypes;
  contentStyle = { fontStyle: '', fontSize: '', fontFamily: '', color: '' };
  fileList = [];
  formatList = [];
  allowedFiles = '';
  fileLimit: number;
  notFoundImage: string;
  RfpQuillConfig;

  constructor(
    private commonService: CommonService,
    private configService: ConfigService,
    private uitilityService: UtilityMethodsService,
    private uploaderService: UploaderService,
    private readonly notificationService: NotificationService,
    private tinyMceService: TinyMceService,
    private i18Next: I18NextPipe
  ) {
    this.notFoundImage = `${this.configService.s3PublicUrl}${ASSETS.NO_DATA_FOUND_IMAGE_PATH}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isSectionDragStarted = false;
    this.isSubSectionDragStarted = false;
  }

  ngOnInit(): void {
    this.loadFileFormats();
  }

  initializeEditor(placeholder) {
    return this.tinyMceService.getInitialConfigs(this.contentStyle, placeholder);
  }

  /**
   * Event handler for section drag event
   * @param sectionDragEvent
   */
  sectionDragStarted(sectionDragEvent: CdkDragStart) {
    this.isSectionDragStarted = true;
  }

  subSectionDragStarted(sectionDragEvent: CdkDragStart) {
    this.isSubSectionDragStarted = true;
  }

  disableSectionsReorder() {
    return this.sections.some((section) => section.isNew || (section?.fields?.length && section.fields.some((field) => field?.isNew || field?.isEdited)));
  }

  disableSubSectionsRorder(sectionIndex: number) {
    return this.sections?.[sectionIndex]?.fields?.length && this.sections?.[sectionIndex]?.fields?.some((field) => field?.isNew || field?.isEdited);
  }

  async downloadDocument({ key, name }) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.DOWNLOAD, fileKey: key, fileName: name }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }

  /**
   * Get previous text diff values
   */
  getPreviousValues(index: number, values: SowDynamicFormsValue) {
    if (values?._id) {
      const foundItemIndex = this.initalSections[index].fields.findIndex((displayItem) => displayItem?._id === values._id);
      if (foundItemIndex > -1) {
        return this.initalSections[index].fields[foundItemIndex]?.commonData?.text !== values?.commonData?.text
          ? this.initalSections[index].fields[foundItemIndex]?.commonData?.text
          : this.isInvitedFirm
            ? values?.firmData?.text
            : values?.clientData?.text;
      }
    } else {
      return this.isInvitedFirm ? values?.firmData?.text : values?.clientData?.text;
    }
  }

  isValidateEnabled(fieldData: SoWFieldValidationData) {
    if (fieldData?.type === this.dynamicFieldDataTypes.UPLOAD) {
      return (
        !isEqual(
          this.isInvitedFirm
            ? fieldData?.firmData?.[FieldKeyBasedOnType[fieldData?.type]]?.['key']
            : fieldData?.clientData?.[FieldKeyBasedOnType[fieldData?.type]]?.['key'],
          fieldData?.commonData?.[FieldKeyBasedOnType[fieldData?.type]]?.[0]?.['key']
        ) && fieldData?.isValidated === 2
      );
    } else if (fieldData?.type === this.dynamicFieldDataTypes.TEMPLATE) {
      return (
        (!isEqual(
          fieldData?.[this.isInvitedFirm ? 'firmData' : 'clientData']?.[FieldKeyBasedOnType[fieldData?.type]]?.['key'],
          fieldData?.commonData?.[FieldKeyBasedOnType[fieldData?.type]]?.[0]?.['key']
        ) ||
          !isEqual(
            fieldData?.[this.isInvitedFirm ? 'firmData' : 'clientData']?.[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]?.['key'],
            fieldData?.commonData?.[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]?.[0]?.['key']
          )) &&
        fieldData?.isValidated === 2
      );
    }
    return (
      !isEqual(
        this.isInvitedFirm ? fieldData?.firmData?.[FieldKeyBasedOnType[fieldData?.type]] : fieldData?.clientData?.[FieldKeyBasedOnType[fieldData?.type]],
        fieldData?.commonData?.[FieldKeyBasedOnType[fieldData?.type]]
      ) && fieldData?.isValidated === 2
    );
  }

  isFieldValid(field) {
    return field?.some(
      (data: SowDynamicFormsValue) =>
        data?.isActive &&
        (!data.title?.length ||
          (data.type === this.dynamicFieldDataTypes.QUILL && this.commonService.checkQuillValueIsEmpty(data?.commonData?.text)) ||
          (data.type === this.dynamicFieldDataTypes.UPLOAD && !(data.commonData.document[0]?.name || data.commonData.document[0]?.key)) ||
          (data.type === this.dynamicFieldDataTypes.TEMPLATE && !(data.commonData.template[0]?.name || data.commonData.document[0]?.key)))
    );
  }

  isButtonDisabled() {
    return this.sections.some((section, i) => {
      return !section?.title?.length || (section?.fields?.length && this.isFieldValid(section.fields));
    });
  }

  checkSectionValues() {
    return isEqual(this.initalSections, this.sections);
  }

  async loadFileFormats() {
    const data = await this.uitilityService.loadAllowedFileFormats();
    this.fileList = data.fileList;
    this.formatList = data.formatList;
    this.allowedFiles = data.allowedFiles;
    this.fileLimit = data.fileLimit;
  }

  onQuillCreated(quillEditor: any) {
    quillEditor.disable();
    if (this.contentStyle) {
      quillEditor.format('font', this.contentStyle.fontFamily);
      quillEditor.format('size', this.contentStyle.fontSize);
      if (Array.isArray(this.contentStyle.fontStyle)) {
        this.contentStyle.fontStyle.forEach((style) => {
          quillEditor.format(style, true);
        });
      }
      quillEditor.format('color', this.contentStyle.color);
    }
    setTimeout(() => {
      quillEditor.enable();
    }, 100);
  }

  onValueChanges(parentIndex: number, childIndex: number) {
    const currentItem = this.sections[parentIndex]?.fields[childIndex];

    const initialItem = this.initalSections[parentIndex]?.fields.find((displayItem) => displayItem?._id === currentItem?._id);

    if (currentItem && initialItem) {
      const commonDataKey = FieldKeyBasedOnType[currentItem.type];

      const isEdited =
        !isEqual(currentItem?.commonData[FieldKeyBasedOnType[currentItem.type]], initialItem?.commonData[FieldKeyBasedOnType[initialItem.type]]) ||
        (currentItem.type === this.dynamicFieldDataTypes.TEMPLATE &&
          !isEqual(
            currentItem?.commonData[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]],
            initialItem?.commonData[FieldKeyBasedOnType[this.dynamicFieldDataTypes.UPLOAD]]
          )) ||
        currentItem?.title !== initialItem?.title;
      currentItem.isEdited = isEdited;

      if (isEdited) {
        this.sections[parentIndex].isEdited = true;
      } else {
        this.sections[parentIndex].isNew = true;
      }
    }
  }

  emitDropSection(event) {
    this.dropSection.emit(event);
  }

  emitOpenAppendixDrawer(isSection, isEdit, parentIndex = -1, childIndex = -1) {
    this.openAppendixDrawer.emit({ isSection, isEdit, parentIndex, childIndex });
  }

  emitSaveDetails() {
    this.saveSectionDetails.emit();
  }

  emitDeleteSection(index) {
    this.deleteSection.emit(index);
  }

  emitDropSubSection(event: CdkDragDrop<void>, parentIndex: number, childIndex: number) {
    this.dropSubSection.emit({ event, parentIndex, childIndex });
  }

  emitOnDelete(parentIndex: number, indexToDelete: number) {
    this.onDelete.emit({ parentIndex, indexToDelete });
  }

  emitChangeActivationStatus(parentIndex: number, childIndex: number) {
    this.changeActivationStatus.emit({ parentIndex, childIndex });
  }

  emitOpenComments(parentIndex: number, childIndex: number) {
    this.openComments.emit({ parentIndex, childIndex });
  }

  emitValidateField(parentIndex: number, childIndex: number) {
    this.validateField.emit({ parentIndex, childIndex });
  }

  async viewDoc(key) {
    try {
      const response = await this.uploaderService.getViewUrl({ responseType: ViewType.VIEW, fileKey: key }).toPromise();
      window.open(response?.data?.viewUrl);
    } catch (error) {
      this.notificationService.openErrorSnackBar(`Couldn't download the document`);
    }
  }
}
