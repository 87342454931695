import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
// tslint:disable-next-line: nx-enforce-module-boundaries
// import { Environment } from '../../../../mozhi/src/lib/entities/environment';

/**
 * Prefixes all requests with `environment.APIEndpoint`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  protected environment: any;

  constructor(@Inject('AppEnvironment') environmentConfig) {
    this.environment = environmentConfig;
  }
  // creating an interface for HttpRequest type is not necessary thats why object mentioned
  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<Event>> {
    // Exclude TranslateModule Url
    const s3Pattern = /^https:\/\/.*.s3./;
    if (s3Pattern.test(request.url)) {
      return next.handle(request);
    } else if (request.url.includes('file/scan')) {
      request = request.clone({
        url: this.environment.socketUrl + request.url
      });
      return next.handle(request);
    } else if (request.url.startsWith('/assets')) {
      request = request.clone({
        url: this.environment.systemBase + request.url
      });
    } else if (!request.url.startsWith('.')) {
      request = request.clone({ url: this.environment.APIEndpoint + request.url });
    }
    return next.handle(request);
  }

}
