import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilityMethodsService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT_TYPE, DeleteConfirmationComponent, NotificationService, WebsiteRegex, fileInputDocument, CONSTANTS, UploaderService } from '@conpulse-web/core';
import { tap } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'conpulse-web-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent implements OnInit {
  driveLink: string = '';
  @Input() onlyDocumentUpload: boolean = false;
  @Input() set accept(allowedFormat: string[]) {
    this.allowedFormats = allowedFormat;
    this.formatDisplayText = this.allowedFormats.reduce((acc: Array<string>, format: string) => {
      acc.push(format.substring(1));
      return acc;
    }, []);
  }
  @Input() fileLimit: [] = [];
  @Input() set document(value: fileInputDocument[]) {
    value?.forEach((doc) => {
      if (doc?.type === DOCUMENT_TYPE.DOCUMENT && !doc?.file) doc['isUploaded'] = true;
    });
    this.documents = value;
  }
  @Input() showUploadSection = true;
  @Input() maxCount: number = 2;
  @Input() disabled: boolean = false;
  @Input() showDelete = true;
  @Input() showDocViewBtn = false;
  @Input() multiple: boolean = false;
  @Input() cardMaxWithSelector: string = 'mw-300';
  @Output() download: EventEmitter<{ key: string, name: string }> = new EventEmitter();
  @Output() view: EventEmitter<string> = new EventEmitter();
  @Output() docUploaded: EventEmitter<fileInputDocument[]> = new EventEmitter();
  @ViewChild('fileUpload') fileInput: ElementRef;
  documents: fileInputDocument[] = [];
  formatDisplayText: string[];
  allowedFormats: string[] = [];
  isLinkAlreadyExists: boolean = false;
  websiteRegex = WebsiteRegex;
  constructor(private utilityService: UtilityMethodsService, private notificationService: NotificationService, private dialog: MatDialog, private uploaderService: UploaderService, private i18nextPipe: I18NextPipe) { }

  ngOnInit(): void { }

  onFileInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;
    if (fileList.length + this.documents.length > this.maxCount) {
      this.notificationService.openErrorSnackBar(`You are only allowed to upload a maximum of ${this.maxCount} ${this.maxCount > 1 ? 'files' : 'file'}`);
      return;
    }
    if (fileList?.length) {
      this.onFileUpload(fileList);
    }
  }

  onFileUpload(files: FileList) {
    for (const file of Array.from(files)) {
      this.uploaderService.scanFile(file).pipe(
        tap((data) => {
          if (data.isInfected) {
            this.notificationService.openErrorSnackBar(`${file.name} ${this.i18nextPipe.transform('file contains malicious content', {ns: 'Snackbar'})}`);
            return;
          }
          const fileName = file.name;
          const fileSize = file.size;
          const fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
          const fileSupported = this.allowedFormats.includes(fileExtension);
          const maxFileSize = this.utilityService.findMaxFileSize(this.fileLimit, fileExtension);
          if (!fileSupported) {
            this.notificationService.openErrorSnackBar(`${fileName} ${this.i18nextPipe.transform('cannot be uploaded due to an unsupported format', { ns: 'Snackbar' })}`);
            return;
          } else if (file.size > maxFileSize * Math.pow(10, 6)) {
            this.notificationService.openErrorSnackBar(`Total size of the file ${file.name} should be less than ${maxFileSize} MB`);
            return;
          } else if (this.documents.some((file) => file.name === file.name)) {
            this.notificationService.openErrorSnackBar(`${this.i18nextPipe.transform('Duplicate file', { ns: 'Snackbar' })}  ${file.name}`);
            return;
          } else {
            this.documents.push({ name: file.name, size: fileSize, file: file, type: DOCUMENT_TYPE.DOCUMENT, isUploaded: false });
            this.updateParent();
          }
        })
      ).subscribe();
    }
  }

  addLink() {
    this.documents.push({ key: this.driveLink, type: DOCUMENT_TYPE.LINK, isUploaded: true });
    this.updateParent();
    this.updateLinkAlreadyExists();
    this.driveLink = '';
  }

  onRemoveDocument(index: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent,CONSTANTS.DELETE_DIALOG_CONFIG);
    const dialogInstance = dialogRef.componentInstance;
    dialogInstance.title = CONSTANTS.DELETE_DIALOG_CONFIG.title;
    dialogInstance.acceptanceText = CONSTANTS.DELETE_DIALOG_CONFIG.acceptanceText;
    dialogInstance.message = CONSTANTS.DELETE_DIALOG_CONFIG.message;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.fileInput) this.fileInput.nativeElement.value = '';
        this.documents.splice(index, 1);
        this.updateParent();
        this.notificationService.openSuccessSnackBar(this.i18nextPipe.transform('Deleted successfully', { ns: 'Snackbar' }));
      }
    });
  }

  updateParent() {
    this.docUploaded.emit(this.documents);
  }

  updateLinkAlreadyExists() {
    this.isLinkAlreadyExists = this.documents.some(({ key, type }) => key === this.driveLink && type === DOCUMENT_TYPE.LINK);
  }

  onDownload(document: fileInputDocument) {
    if (document?.key?.length) {
      document.type === DOCUMENT_TYPE.LINK ? window.open(document.key) : this.download.emit({ key: document.key, name: document.name });
    }
  }

  viewDoc(document: fileInputDocument) {
    if (document?.key?.length) {
      document.type === DOCUMENT_TYPE.LINK ? window.open(document.key) : this.view.emit(document.key);
    }
  }
}
