import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import {
  DeleteConfirmationComponent,
  DynamicFieldModelSpec,
  Language,
  LayoutAndTemplateData,
  LayoutSections,
  Category,
  NotificationService,
  SoWDefaultSections,
  NdaDefaultSections,
  TemplateEditorMode,
  TemplateType,
  UserService,
  TemplateLibraryService,
  RFPDefaultSections,
  MsaDefaultSections,
  sowDynamicTabNames,
  MsaDynamicTabNames,
  MsaDefaultDynamicFieldsValues,
  SoWDynamicFieldValues,
  MsaDynamicFieldName,
  AllDynamicKeyName,
  NdaDynamicFieldValues,
  UploadFileDetails,
  UploadType,
  UploaderService,
  TemplateDocType,
  FontFamily,
  FontSize,
  Style,
  styleSections,
  DynamicDesignModelSpec,
  ContentStyle,
  DynamicTemplateSections,
  DynamicFieldDataTypes,
  DynamicFormsValue,
  Colors,
  StyleType,
  DefaultStyleText,
  ndaDynamicTabNames,
  RFIDefaultSections,
  OnBoardingEditDrawerType,
  rfpDynamicTabNames,
  RfpDefaultDynamicFieldsValues
} from '@conpulse-web/core';
import { TinyMceService, UtilityMethodsService } from '@conpulse-web/conpulse';
import { Subscription } from 'rxjs';
import { I18NextPipe } from 'angular-i18next';
import { cloneDeep, get, includes, isEmpty, isEqual, set } from 'lodash-es';

@Component({
  selector: 'conpulse-web-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
  get = get;

  Category = TemplateType;
  onBoardingDrawerType = OnBoardingEditDrawerType;
  SoWDefaultSections = SoWDefaultSections;
  RfpSettingsQuillConfig: object = null;
  loaderDiameter: number = 22;
  loaderWidth: number = 3;
  isLoading: boolean = false;
  isUploading: boolean = false;
  isSaving: boolean = false;
  isSuperAdmin: boolean = false;
  languageList: Language[] = [];
  categoryType = Category;
  templateData: LayoutAndTemplateData = {} as LayoutAndTemplateData;
  initialTemplateData: LayoutAndTemplateData = {} as LayoutAndTemplateData;
  templateSpec: DynamicFieldModelSpec[] = LayoutSections;
  templateModel: object = {};
  initialTemplateModel: object = {};
  styleSection: DynamicDesignModelSpec[] = styleSections;
  styleModel: object = {};
  initialStyleModel: object = {};
  editorTitle = '';
  templateCategory: Category | null = null;
  templateType: TemplateType | null = null;
  templateEditorMode: TemplateEditorMode = TemplateEditorMode.ADD;
  templateId: string = '';
  quillUploadingSubscription: Subscription = null;
  dynamicTabs = [];
  dynamicDataForUpdate = {};
  initialDynamicDataForUpdate = {}
  dynamicConstants = {};
  viewer = [];
  isInputSame: boolean = true;
  fontFamily = FontFamily;
  fontSize = FontSize;
  fontStyle = Style;
  contentStyle: ContentStyle = { fontStyle: '', fontSize: '', fontFamily: '', color: '' };
  editEnabled: { fieldIndex: number; sectionIndex: number } = {} as { fieldIndex: number; sectionIndex: number };
  sectionInvalid: boolean = false;
  specificQuestion = [];
  isToggle = false;
  styleType = StyleType;
  colorCodes = Colors;
  colorMenuToggle = {
    pageTitle: true,
    sectionTitle: true,
    subSectionTitle: true,
    subTopicTitle: true,
    contentStyle: true,
  };
  defaultStyleText = DefaultStyleText;
  preview = {
    pageTitle: '',
    sectionTitle: '',
    subSectionTitle: '',
    subTopicTitle: '',
    contentStyle: '',
    footerMargin: ''
  };

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private templateLibrary: TemplateLibraryService,
    private notificationService: NotificationService,
    private utility: UtilityMethodsService,
    private readonly uploaderService: UploaderService,
    private i18Next: I18NextPipe,
    private readonly tinyMceService: TinyMceService,
  ) {}

  ngOnInit(): void {
    this.styleModel = this.utility.buildDynamicDesignModel(this.styleSection);
    this.initializeDefaultStyle();
    this.initialStyleModel = cloneDeep(this.styleModel);
    this.initializeEditor();
  }

  initializeDefaultStyle() {
    const commonStyle = {
      fontFamily: 'Roboto',
      color: '#000000',
    };
    Object.keys(this.styleModel).forEach((style) => {
      switch (style) {
        case StyleType.PAGE_TITLE:
          this.styleModel[style] = {
            fontSize: '24px',
            ...commonStyle,
          };
          break;
        case StyleType.SECTION_TITLE:
          this.styleModel[style] = {
            fontSize: '16px',
            ...commonStyle,
          };
          break;
        case StyleType.SUBSECTION_TITLE:
          this.styleModel[style] = {
            fontSize: '14px',
            ...commonStyle,
          };
          break;
        case StyleType.SUB_TOPIC_TITLE:
          this.styleModel[style] = {
            fontSize: '14px',
            ...commonStyle,
            };
            break;
        case StyleType.CONTENT_STYLE:
          this.styleModel[style] = {
            fontSize: '12px',
            ...commonStyle,
          };
          break;
        case StyleType.FOOTER_MARGIN:
          this.styleModel[style] = {
              'color': '#bbbbbb',
            };
            break;
      }
      this.constructStyle(style);
    });
  }

  initEditor = (fieldName) => this.tinyMceService.getInitialConfigs('', fieldName);

  /**
   * Initializes Template Editor
   */
  initializeEditor() {
    this.dynamicTabs = [];
    this.dynamicDataForUpdate = {};
    this.dynamicConstants = {};
    this.isLoading = true;
    // this.quillUploadingSubscription = this.utility.quillUploading.subscribe((isUploading) => (this.isUploading = isUploading));
    this.userService.checkToken('validateLogin').subscribe((data) => {
      this.isSuperAdmin = data.isSuperAdmin || false;
      this.userService.getLanguages().subscribe((languageData) => {
        this.languageList = get(languageData, 'data', []);
        const editorTitleParts: string[] = [];
        this.route.queryParams.subscribe((queryParams) => {
          this.isToggle = queryParams['type'] === TemplateType.RFP || queryParams['type'] === TemplateType.RFI;
          switch (queryParams['libraryType']) {
            case Category.LAYOUT:
              editorTitleParts.push(this.i18Next.transform('Layout Template'));
              switch (queryParams['type']) {
                case TemplateType.SOW:
                  this.templateSpec = LayoutSections;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('SOW'));
                  break;
                case TemplateType.RFP:
                  this.templateSpec = LayoutSections;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('RFP'));
                  break;
                case TemplateType.NDA:
                  this.templateSpec = LayoutSections;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('NDA'));
                  break;
                case TemplateType.MSA:
                  this.templateSpec = LayoutSections;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('MSA'));
                  break;
                case TemplateType.RFI:
                  this.templateSpec = LayoutSections;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('RFI'));
                  break;
                default:
                  this.exitEditor(true);
                  break;
              }
              break;
            case Category.TEMPLATE:
              editorTitleParts.push(this.i18Next.transform('Template'));
              switch (queryParams['type']) {
                case TemplateType.SOW:
                  this.templateSpec = SoWDefaultSections;
                  this.dynamicTabs = sowDynamicTabNames;
                  this.dynamicConstants = JSON.parse(JSON.stringify(SoWDynamicFieldValues));
                  editorTitleParts.splice(0, 0,  this.i18Next.transform('SOW'));
                  break;
                case TemplateType.NDA:
                  this.templateSpec = NdaDefaultSections;
                  this.dynamicTabs = ndaDynamicTabNames;
                  this.dynamicConstants = JSON.parse(JSON.stringify(NdaDynamicFieldValues));
                  editorTitleParts.splice(0, 0,  this.i18Next.transform('NDA'));
                  break;
                case TemplateType.RFP:
                  this.templateSpec = RFPDefaultSections;
                  this.dynamicTabs = rfpDynamicTabNames;
                  this.dynamicConstants = JSON.parse(JSON.stringify(RfpDefaultDynamicFieldsValues))
                  editorTitleParts.splice(0, 0, this.i18Next.transform('RFP'));
                  break;
                case TemplateType.MSA:
                  this.templateSpec = MsaDefaultSections;
                  this.dynamicTabs = MsaDynamicTabNames;
                  this.dynamicConstants = JSON.parse(JSON.stringify(MsaDefaultDynamicFieldsValues));
                  editorTitleParts.splice(0, 0, 'MSA');
                  break;
                case TemplateType.RFI:
                  this.templateSpec = RFIDefaultSections;
                  this.dynamicTabs = rfpDynamicTabNames;
                  this.dynamicConstants = JSON.parse(JSON.stringify(RfpDefaultDynamicFieldsValues))
                  editorTitleParts.splice(0, 0, this.i18Next.transform('RFI'));
                  break;
                default:
                  this.exitEditor(true);
                  break;
              }
              this.dynamicDataForUpdate = JSON.parse(JSON.stringify({ ...this.dynamicConstants }));
              this.initialDynamicDataForUpdate = cloneDeep(this.dynamicDataForUpdate)
              break;
          }
          this.templateCategory = queryParams['libraryType'];
          this.templateType = queryParams['type'];
          this.templateModel =
            this.isToggle && this.templateCategory != this.categoryType.LAYOUT
              ? this.utility.buildRfpDynamicFieldModel(this.templateSpec)
              : this.utility.buildDynamicFieldModel(this.templateSpec);
          this.initialTemplateModel = cloneDeep(this.templateModel);
          this.route.parent.url.subscribe((url) => {
            const editorMode = url[1].path;
            switch (editorMode) {
              case TemplateEditorMode.ADD:
                this.templateId = '';
                this.templateData.language = data.user.companyLanguage || null;
                editorTitleParts.splice(0, 0, this.i18Next.transform('Add'));
                this.isLoading = false;
                break;
              case TemplateEditorMode.EDIT:
                this.route.params.subscribe(({ id: templateId }) => {
                  this.templateId = templateId;
                  editorTitleParts.splice(0, 0, this.i18Next.transform('Edit'));
                  this.loadTemplateData();
                });
                break;
              default:
                this.exitEditor(true);
                break;
            }
            this.editorTitle = editorTitleParts.join(' ');
          });
        });
      });
    });
  }

  // initializeEditor(placeholder = '') {
  // }

  trackByField(index: number, field): string {
    return field.fieldKey;
  }

  /**
   * update toggle to select color
   * @param key
   */
  updateMenuToggle(keyToExclude) {
    for (const key in this.colorMenuToggle) {
      if (key !== keyToExclude) {
        this.colorMenuToggle[key] = true;
      } else {
        this.colorMenuToggle[key] = !this.colorMenuToggle[key];
      }
    }
  }
  /**
   * select the color for titles
   * @param key
   * @param colorCode
   */
  selectColor(key, colorCode) {
    this.styleModel[key]['color'] = colorCode;
    this.colorMenuToggle[key] = !this.colorMenuToggle[key];
    this.constructStyle(key);
  }

  /**
   * constructColor style for preview
   * @param key
   */
  constructStyle(key) {
    let style = '';
    if (this.styleModel[key].fontStyle?.length) {
      this.styleModel[key].fontStyle.forEach((data) => {
        switch (data) {
          case 'bold':
            style += 'font-weight:bold;';
            break;
          case 'underline':
            style += 'text-decoration:underline;';
            break;
          case 'italic':
            style += 'font-style:italic;';
            break;
          default:
            style += `font-style:${data};`;
        }
      });
    }
    this.preview[key] = `font-size:${this.styleModel[key].fontSize};${style}font-family:${this.styleModel[key].fontFamily};color:${this.styleModel[key].color}`;
  }

  /**
   * activate or deactivate the fields
   * @param sectionName
   * @param fieldName
   * @param event
   */
  updateToggleValue(sectionName: string, fieldName: string, event) {
    this.templateModel[sectionName][fieldName].isActive = event;
  }
  /**
   * Exits Template Editor and navigates back depending on the context.
   * @param isError Boolean value indicating whether the exit is because of error
   */
  exitEditor(isError: boolean = false) {
    if (isError) {
      this.notificationService.openErrorSnackBar('Invalid template editor parameters');
    }
    if (!isEqual(this.templateModel, this.initialTemplateModel) || !isEqual(this.templateData, this.initialTemplateData)) {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        disableClose: true,
        width: '450px',
      });
      dialogRef.componentInstance.title = 'Discard changes';
      dialogRef.componentInstance.message = 'Are you sure you want to discard the changes?';
      dialogRef.componentInstance.acceptanceText = 'Yes';
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.navigateBack();
        }
      });
    } else {
      this.navigateBack();
    }
  }

  /**
   * Navigates back depending on the user context.
   */
  navigateBack() {
    switch (this.templateType) {
      case TemplateType.SOW:
        this.isSuperAdmin
          ? this.router.navigate(['/dashboard/adminSettings/sow'], { queryParams: { libraryType: this.templateCategory } })
          : this.router.navigate(['/dashboard/otherSettings/sow'], { queryParams: { libraryType: this.templateCategory } });
        break;
      case TemplateType.RFP:
        this.isSuperAdmin
          ? this.router.navigate(['/dashboard/adminSettings/rfp'], { queryParams: { libraryType: this.templateCategory } })
          : this.router.navigate(['/dashboard/otherSettings/rfp'], { queryParams: { libraryType: this.templateCategory } });
        break;
      case TemplateType.NDA:
        this.isSuperAdmin
          ? this.router.navigate(['/dashboard/adminSettings/nda'], { queryParams: { libraryType: this.templateCategory } })
          : this.router.navigate(['/dashboard/otherSettings/nda'], { queryParams: { libraryType: this.templateCategory } });
        break;
      case TemplateType.MSA:
        this.isSuperAdmin
          ? this.router.navigate(['/dashboard/adminSettings/msa'], { queryParams: { libraryType: this.templateCategory } })
          : this.router.navigate(['/dashboard/otherSettings/msa'], { queryParams: { libraryType: this.templateCategory } });
        break;
      case TemplateType.RFI:
        this.isSuperAdmin
          ? this.router.navigate(['/dashboard/adminSettings/rfi'], { queryParams: { libraryType: this.templateCategory } })
          : this.router.navigate(['/dashboard/otherSettings/rfi'], { queryParams: { libraryType: this.templateCategory } });
        break;
    }
  }

  /**
   * Loads Template Data from API
   */
  loadTemplateData() {
    this.isLoading = true;
    // this.dynamicConstants = {}
    this.templateLibrary.getTemplateAndLayoutById(this.templateId).subscribe(
      (response) => {
        this.specificQuestion = response?.data?.templateData?.specificQuestions || [];
        this.templateData = get(response, 'templateData', {} as LayoutAndTemplateData);
        this.initialTemplateData = cloneDeep(this.templateData);
        Object.entries(this.initialTemplateData.data)?.forEach(([key, value]) => {
          if (includes(Object.keys(AllDynamicKeyName), key)) {
            set(this.dynamicConstants, AllDynamicKeyName[key], value?.length ? value : []);
          }
        });
        this.dynamicDataForUpdate = JSON.parse(JSON.stringify({ ...this.dynamicConstants }));
        this.initialDynamicDataForUpdate = cloneDeep(this.dynamicDataForUpdate)
        this.templateModel = this.utility.assignDataToDynamicFieldModel(this.templateModel, this.templateData.data);
        this.initialTemplateModel = cloneDeep(this.templateModel);
        this.styleModel = this.utility.assignDataToDynamicFieldModel(this.styleModel, this.templateData.style);
        this.initialStyleModel = cloneDeep(this.styleModel);
        for (let key in this.styleModel) {
          this.constructStyle(key);
        }
        this.isLoading = false;
      },
      (error) => {
        if (error.code !== 404) this.notificationService.openErrorSnackBar('Unable to load template data');
        this.navigateBack();
      }
    );
  }

  /**
   * Uploads documents to S3
   */
  async uploadDocumentToS3(file: File) {
    const fileName = file?.name
    const mimeType = file?.type;
    const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    const fileDetails: UploadFileDetails = {
      uploadType: this.isSuperAdmin ? (this.templateType === TemplateType.SOW ? UploadType.SA_SOW_TEMPLATE : UploadType.SA_MSA_TEMPLATE) : (this.templateType === TemplateType.SOW ? UploadType.SOW_TEMPLATE : UploadType.MSA_TEMPLATE),
      fileKey: `${uuidv4()}.${fileType}`,
      mimeType: mimeType,
    };
    const urls = get(await this.uploaderService.getUploadUrl(fileDetails, { clientId: this.userService.currentUserInformation.companyId?.['_id']}).toPromise(), 'data', null);
    const uploadUrl: string = urls?.uploadUrl;
    if (uploadUrl) {
      try {
        await this.uploaderService.uploadToPresignedUrl(uploadUrl, file, mimeType).toPromise();
        return urls.filePath;
      } catch (err) {
        this.notificationService.openErrorSnackBar('Could not upload image!');
      }
    } else {
      this.notificationService.openErrorSnackBar('Could not upload image!');
    }
  }

  /**
   * Add general provisions values to data
   * @param values
   */
  async onAddDynamicFields(values: { added: Object[]; templateDocument: TemplateDocType[]; uploadDocumentOrLink: TemplateDocType[] }, type: string) {
    const updated = values?.added.filter((item: { title: string }) => item?.title);
    this.dynamicDataForUpdate[type] = updated;
    this.dynamicConstants[type] = updated;
  }

  /**
   * Dynamic Sections Details
   */
  onAddDynamicSections(section: DynamicTemplateSections[], type: string) {
    this.dynamicDataForUpdate[type] = [...section];
  }

  /**
   * Updates Section titles
   */
  onSectionTitleChange(sectionDetails: { title: string; index: number }, type: string) {
    if (!isEmpty(this.dynamicDataForUpdate[type]?.[sectionDetails.index])) this.dynamicDataForUpdate[type][sectionDetails.index].title = sectionDetails.title;
  }

  /**
   * Saves Template Data to DB
   */
  saveTemplateData() {
    this.isSaving = true;
    this.templateData.data = cloneDeep(this.templateModel);
    this.templateData.style = cloneDeep(this.styleModel);
    Object.entries(this.dynamicConstants).forEach(([key, value]) => {
      if (includes(this.dynamicTabs, key)) {
        this.dynamicDataForUpdate[key] = this.dynamicDataForUpdate[key].map((data, index) => ({ ...data, index }));
        set(this.templateData.data, MsaDynamicFieldName[key], this.dynamicDataForUpdate[key]);
      }
    });
    if (!this.templateData?.category) this.templateData.category = this.templateCategory;
    if (!this.templateData?.type) this.templateData.type = this.templateType;
    this.templateLibrary.addOrUpdateLayoutAndTemplate(this.templateData).subscribe(
      () => {
        const notificationText = this.templateData._id ? 'updated' : 'added';
        this.tinyMceService.saveButtonTrigger$.next({ isClicked: true, diffArray: [] });
        this.notificationService.openSuccessSnackBar(this.i18Next.transform(`Template ${notificationText} successfully`, { ns: 'Snackbar' }));
        this.initialTemplateData = cloneDeep(this.templateData);
        this.isSaving = false;
        this.navigateBack();
      },
      (response) => {
        this.notificationService.openErrorSnackBar(response.message);
        this.isSaving = false;
      }
    );
  }

  /**
   * The function `processAppendix` processes an array of appendix objects, extracting and uploading
   * documents from specific fields, and returning the processed appendix objects with the uploaded
   * document URLs.
   * @param {{fields: DynamicFormsValue[] }[]} appendix - The `appendix` parameter is an array of
   * objects. Each object has a `fields` property, which is an array of `DynamicFormsValue` objects.
   * @returns The function `processAppendix` returns an array `returnAppendix` which contains objects
   * with the following properties:
   */
  async processAppendix(appendix: { fields: DynamicFormsValue[] }[]) {
    const returnAppendix = [];
    for (let i = 0; i < appendix.length; i++) {
      const returnData = { ...appendix[i] };
      const mappedArray = await Promise.all(
        returnData?.fields?.map(async (field, fieldIndex) => {
          this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex] = { ...this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex], index: fieldIndex };
          if (
            field.type === DynamicFieldDataTypes.TEMPLATE &&
            (field?.commonData?.template?.templateRes?.isNewUpload || field?.commonData?.template?.templateReq?.isNewUpload)
          ) {
            const templateRes = field?.commonData?.template?.templateRes?.isNewUpload ? 'templateRes' : 'templateReq';
            let document = field?.commonData?.template?.[templateRes];
            if (document?.file) {
              const documentUrl = (await this.uploadDocumentToS3(document['file'] as File)) || '';
              this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex]['commonData']['template'][templateRes]['key'] = documentUrl;
              this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex]['commonData']['template'][templateRes]['isNewUpload'] = undefined;
              return documentUrl;
            }
          }
          if (field.type === DynamicFieldDataTypes.UPLOAD && field?.commonData?.upload?.isNewUpload && field?.commonData?.upload?.file) {
            const documentUrl = (await this.uploadDocumentToS3(field.commonData.upload.file as File)) || '';
            this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex]['commonData']['upload']['key'] = documentUrl;
            this.dynamicDataForUpdate['Appendix'][i].fields[fieldIndex]['commonData']['upload']['isNewUpload'] = undefined;
            return documentUrl;
          }
        })
      );
      returnAppendix.push({ ...returnData, keys: mappedArray });
    }
    return returnAppendix;
  }
  /**
   * The function "uploadDocument" asynchronously processes and appends data to an appendix, saves
   * template data, and displays an error message if there is an issue with uploading files.
   */
  async uploadDocument() {
    this.isSaving = true;
    this.processAppendix(this.dynamicDataForUpdate['Appendix'])
      .then((result) => {
        this.saveTemplateData();
      })
      .catch((error) => {
        this.notificationService.openErrorSnackBar(get(error, 'message', 'Unable to upload files'));
      });
  }

  validateChanges() {
    this.isInputSame = isEqual(this.initialTemplateModel, this.templateModel) && isEqual(this.initialTemplateData, this.templateData);
  }

  /**
   * Determines whether the button is disabled or not
   * @returns Boolean value indicating button disabled status
   */
  isSaveDisabled() {   
    return (
      this.isLoading ||
      this.isSaving ||
      this.isUploading ||
      this.sectionInvalid ||
      (isEqual(this.templateModel, this.initialTemplateModel) && isEqual(this.templateData, this.initialTemplateData) && isEqual(this.initialDynamicDataForUpdate, this.dynamicDataForUpdate)) 
    );
  }

  /**
   * The function updates the validity of a section based on a boolean value.
   * @param {boolean} isInValid - A boolean value indicating whether the section is valid or not.
   */
  updateSectionValidity(isInValid: boolean) {
    this.sectionInvalid = isInValid;
  }

  ngOnDestroy(): void {
    this.tinyMceService.saveButtonTrigger$.next({ isClicked: false, diffArray: [] })
    this.tinyMceService.reset$.next(true)
  }
}
