import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TrimDirective } from './directives/trim.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { EmailExistanceValidatorDirective, PasswordValidator } from './validators/index';
import { SafePipe } from './pipes/safe.pipe';
import { TextDiffComponent } from './components/text-diff/text-diff.component';
import { SpecialDiffComponent } from './components/special-diff/special-diff.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { AmountDelimitPipe, ObjectKeyPipe } from './pipes';
import { AmountConversionPipe } from './pipes/amount-conversion.pipe';
import { FocusDirective } from './directives/focus.directive';
import { QuillShowHideDirective } from './directives/quill-show-hide.directive';
import { EllipsisTooltipDirective } from './directives/ellipsisTooltip.directive';
import { MatSelectInfiniteScrollDirective, SanitizeHtmlDirective } from './directives';
import { MinValueValidatorDirective } from './directives/min-value-validator.directive';
import { PermissionDirective } from './directives/permission.directive';
import { MaxValueValidatorDirective } from './directives/max-value-validator.directive';
import { FileDropDirective } from './directives/file-drop.directive';
import { AmountNotationPipe } from './pipes/amount-notation.pipe';
import { AmountSeperatorDirective } from './directives/amount-seperator.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { RestrictSpecialCharDirective } from './directives/restrict-special-char.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TrimDirective,
    FocusDirective,
    FilterPipe,
    TimeAgoPipe,
    EmailExistanceValidatorDirective,
    PasswordValidator,
    SafePipe,
    TextDiffComponent,
    SpecialDiffComponent,
    EllipsisPipe,
    ObjectKeyPipe,
    AmountConversionPipe,
    QuillShowHideDirective,
    EllipsisTooltipDirective,
    MatSelectInfiniteScrollDirective,
    MinValueValidatorDirective,
    PermissionDirective,
    MaxValueValidatorDirective,
    FileDropDirective,
    AmountNotationPipe,
    AmountDelimitPipe,
    AmountSeperatorDirective,
    OnlyNumberDirective,
    RestrictSpecialCharDirective,
    SanitizeHtmlDirective
  ],
  exports: [
    CommonModule,
    TrimDirective,
    FilterPipe,
    TimeAgoPipe,
    EmailExistanceValidatorDirective,
    PasswordValidator,
    SafePipe,
    TextDiffComponent,
    SpecialDiffComponent,
    EllipsisPipe,
    ObjectKeyPipe,
    AmountConversionPipe,
    FocusDirective,
    QuillShowHideDirective,
    EllipsisTooltipDirective,
    MatSelectInfiniteScrollDirective,
    MinValueValidatorDirective,
    PermissionDirective,
    MaxValueValidatorDirective,
    FileDropDirective,
    AmountNotationPipe,
    AmountDelimitPipe,
    AmountSeperatorDirective,
    OnlyNumberDirective,
    RestrictSpecialCharDirective,
    SanitizeHtmlDirective
  ],
})
export class ConpulseModule {}
