<div class="p-x-20 py-12 bg-white border-radius-6 mb-12">
  <div class="d-flex align-items-center justify-content-between">
    <h3 class="page-title font-Jakarta">{{ 'Modules' | i18next }}</h3>
  </div>
</div>

<form role="form" #form="ngForm" class="bg-white border-radius-6" [ngClass]="{'p-20': !isSuperAdmin }">
  <div class="d-flex client-module-container overflow-auto" id="manageModulesDrawer" [ngClass]="{ 'super-admin p-x-20 p-t-20 pb-3': isSuperAdmin }">
    <div class="flex-1">
      <ng-container *ngIf="!isLoading; else loadingRef">
        <ng-container *ngFor="let module of availableModulesList; let i = index">
          <div class="mb-3">
            <mat-checkbox
              color="secondary"
              [disabled]="!isSuperAdmin || defaultModule.includes(module.code)"
              [indeterminate]="checkIndeterminate(module.moduleId)"
              [checked]="checkAllEnabled(module.moduleId)"
              (change)="enableOrDisableModule($event.checked, module.moduleId)"
              [matTooltip]="!isSuperAdmin  || defaultModule.includes(module.code) ? ('Read-only' | i18next) : ''"
              matTooltipPosition="right"
              matTooltipClass="right"
            >
              <p class="f-14 f-w-700 l-h-20 txt-black font-Jakarta">{{ module.name | i18next }}</p>
            </mat-checkbox>
            <div class="child-modules ml-4 mt-2 d-flex">
              <ng-container *ngFor="let split of [0, 1]">
                <div class="flex-1">
                  <ng-container *ngFor="let childModule of module?.children; let i = index">
                    <div class="d-flex flex-column mt-1">
                      <ng-container *ngIf="i % 2 === split">
                        <mat-checkbox
                          color="secondary"
                          [disabled]="!isSuperAdmin"
                          [checked]="isModuleEnabled(module.moduleId, childModule.moduleId)"
                          [matTooltip]="!isSuperAdmin ? ('Read-only' | i18next) : ''"
                          (change)="enableOrDisableModule($event.checked, module.moduleId, childModule.moduleId)"
                          matTooltipPosition="below"
                          matTooltipClass="below"
                          >{{ childModule.name |  i18next}}
                        </mat-checkbox>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #loadingRef>
        <ng-container *ngFor="let module of [0, 1, 2, 3]; let i = index">
          <div class="mb-3">
            <p class="f-14 f-w-700 l-h-20 txt-black font-Jakarta skeleton-loader skeleton-title"></p>
            <div class="child-modules ml-4 d-flex mt-2">
              <ng-container *ngFor="let split of [0, 1]">
                <div class="flex-1">
                  <ng-container *ngFor="let childModule of [1, 2, 3, 4]; let i = index">
                    <div class="d-flex flex-column mt-1">
                      <ng-container *ngIf="i % 2 === split">
                        <p class="f-14 f-w-700 l-h-20 txt-black font-Jakarta skeleton-loader skeleton-title"></p>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="flex-1"></div>
  </div>
  <!-- </div> -->
    <div class="d-flex justify-content-end button-section button-section p-20" *ngIf="isSuperAdmin">
      <button color="primary" type="button" mat-flat-button [disabled]="isLoading || checkButtonDisabled()" (click)="saveClientModuleDetails()">
        {{ isLoading ? 'Loading...' : 'Save' }}
      </button>
    </div>
</form>

