<div class="p-4 position-relative">
  <div class="drawer-header d-flex align-items-center justify-content-between mb-3 top-sticky">
    <h4 class="drawer-title">{{'Detailed View' | i18next}}</h4>
    <div class="d-flex">
      <div class="mr-2">
        <mat-icon class="pointer next-iocn" [class.pointer-events-none]="preNextCount() === 0"
          (click)="movePrevQuestion()">chevron_left</mat-icon>
        <mat-icon class="pointer next-iocn" [class.pointer-events-none]="preNextCount() === totalCount"
          (click)="moveNextQuestion()">chevron_right</mat-icon>
      </div>
      <mat-icon class="drawer-close-icon pointer" (click)="back.emit()">close</mat-icon>
    </div>
  </div>

  <div class="mb-2">
    <p class="mb-2 bold">{{question?.sectionIndex}}. {{question.section}}</p>
    <p class="mb-1">{{question?.sectionIndex + '.' + question.position }}. {{ question?.question?.title }}</p>
    @if(question?.question?.type === 'template') {
    @let doc = question?.question?.template;
    <ng-container *ngTemplateOutlet="myDocTemplate; context: {
                  $implicit: {
                      documentKey: doc?.documentKey,
                      documentName: doc?.documentName,
                      documentType: doc?.documentType === 1 ? 2 : 1
                  }
              }"></ng-container>
    }
  </div>

  <div class="mt-1 compare-table-container">
    <mat-table [dataSource]="source" class="mat-elevation-z8">
      <ng-container matColumnDef="provider">
        <mat-header-cell *matHeaderCellDef> {{ isProvider || viewProposal ? ('PROPOSAL' | i18next) : ('PROVIDER' | i18next) }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          @if(row?.isProvider) {
          @if (!row?.profilePhoto) {
          <span class="mr-2 alphabet" [ngClass]="getProfileClass($index)">
            {{ row?.name?.charAt(0)?.toUpperCase() }}
          </span>

          } @else {
          <span>
            <img class="profile-image mr-2" src="{{ row?.profilePhoto }}" />
          </span>

          }
          }
          {{ row.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ans">
        <mat-header-cell *matHeaderCellDef> {{'ANSWER' | i18next }}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          @let ans = row;
          @switch (ans?.type) {
          @case ('input') {
          <div>
            <p [innerHTML]="ans?.value" class="truncate-text" #tinyMcValue></p>
            @if(checkTruncation(tinyMcValue)) {
            <span class="see-more" (click)="openDialog(ans)">{{'see more' | i18next}}</span>
            }
          </div>
          }
          @case('multipleChoice') {
          @if(ans?.allowMultipleChoice) {
          <!-- <p>{{ans.multipleChoiceAnswers?.join(', ')}}</p> -->
          <mat-chip-listbox>
            @for (option of ans?.multipleChoiceAnswers; track option) {
            <mat-chip>
              {{ option }}
            </mat-chip>
            }
          </mat-chip-listbox>

          }@else {
          <!-- <p class="truncate-text">{{ans?.value}}</p> -->
          <mat-chip-listbox>
            <mat-chip>
              {{ ans.value }}
            </mat-chip>
          </mat-chip-listbox>
          }
          }
          @case('template') {
          @let doc = ans?.document || {};
          @if(doc?.documentKey) {
          <ng-container *ngTemplateOutlet="myDocTemplate; context: { $implicit: doc }"></ng-container>
          } @else {
          <p>-</p>
          }

          }
          @case('upload') {
          @let doc = ans?.document || {};
          @if(doc?.documentKey) {
          <ng-container *ngTemplateOutlet="myDocTemplate; context: { $implicit: doc }"></ng-container>
          } @else {
          <p>-</p>
          }
          }
          @default {
          <p>-</p>
          }
          }
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;" sticky></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

  <div class="bottom-sticky">
    <div class="text-left">
      <button class="mr-12" color="accent" mat-stroked-button type="button" (click)="back.emit()">
        {{ 'Cancel' }}
      </button>
    </div>
  </div>

</div>


<ng-template #myDocTemplate let-document>
  <div
    class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between m-10px">
    <div class="file-details overflow-hidden">
      @let upload = document.documentType === 2;
      <div class="d-flex">
        <div class="doc-image mr-2">
          <img class="img-fit-cover"
            [src]="utilityService.getAssetsPath(upload ? document.documentName : 'temp.url')" />
        </div>
        <p class="f-13 f-w-500 doc-name">{{
          truncateDocumentName( upload ? document.documentName : document.documentKey) }}
        </p>
      </div>
    </div>
    <div class="file-actions ml-1">
      <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
        [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        @if (upload) {
        <button mat-menu-item (click)="downloadDocument(document)">
          <mat-icon>download</mat-icon> {{'Download' | i18next}}
        </button>
        }
        @if(!upload || utilityService.isPreviewAvailable(document?.documentName)) {
        <button mat-menu-item (click)="viewDocument(document)">
          <mat-icon>visibility</mat-icon> {{'View' | i18next}}
        </button>
        }
      </mat-menu>
    </div>
  </div>
</ng-template>