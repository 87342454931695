<div mat-dialog-title class="d-flex justify-content-between align-item-center mb-2">
  @let ans = data?.ans;
  <p>
    @if(ans.isProvider) {
      @if (!ans?.profilePhoto) {
        <span class="mr-2 alphabet" [ngClass]="getProfileClass($index)">
            {{ column?.name?.charAt(0)?.toUpperCase() }}
        </span>
  
        } @else {
        <span>
            <img class="profile-image mr-2" src="{{ ans?.profilePhoto }}" />
        </span>
  
        }
    }
      {{ ans.name }}
  </p>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content>
  @let question = data?.question;
  <div class="mb-2">
    <p class="mb-2">{{question?.sectionIndex}}. {{question.section}}</p>
    <p class="mb-1">{{question?.sectionIndex + '.' + question.position }}. {{ question?.question?.title }}</p>
    @if(question?.question?.type === 'template') {
    @let doc = question?.question?.template;
    <ng-container *ngTemplateOutlet="myDocTemplate; context: { 
                  $implicit: {
                      documentKey: doc?.documentKey,
                      documentName: doc?.documentName,
                      documentType: doc?.documentType === 1 ? 2 : 1
                  }
              }"></ng-container>
    }
  </div>
  <div>
    @switch (ans?.type) {
    @case ('input') {
    <div>
      <p [innerHTML]="safeHtml(ans?.value)"></p>
    </div>
    }
    @case('multipleChoice') {
    @if(ans?.allowMultipleChoice) {
    <mat-chip-listbox>
      @for (option of ans?.multipleChoiceAnswers; track option) {
      <mat-chip>
        {{ option }}
      </mat-chip>
      }
    </mat-chip-listbox>
    }
    }
    @default {
    <p>-</p>
    }
    }
  </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close color="accent">{{ 'Cancel' | i18next }}</button>
</mat-dialog-actions>