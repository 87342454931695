<section class="w-100 hight-fit-content">
  @if(isLoading()) {
    <div class="w-100 h-100 loader">
      <div class="skeleton-table w-100">
          @for (i of [1, 2, 3, 4, 5]; track $index) {
            <div class="d-flex">
                <span class="">&nbsp;</span>
                <span class="">&nbsp;</span>
                <span class="skeleton-loader skeleton-title w-25">&nbsp;</span>
                <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
                <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
                <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
                <span class="skeleton-loader skeleton-title w-12">&nbsp;</span>
            </div>
          }
      </div>
    </div>
  }
  @if (!isLoading()) {
    @if(header().length && answer().length) {
      <div class="w-100 proposal-table-container">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="position" sticky>
              <mat-header-cell *matHeaderCellDef> {{'NO' | i18next }}.</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.position }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="section" sticky>
              <mat-header-cell *matHeaderCellDef> {{'SECTION' | i18next }} </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{ row.section }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="question" sticky>
              <mat-header-cell *matHeaderCellDef> {{'SECTION / QUESTION' | i18next}} </mat-header-cell>
              <mat-cell *matCellDef="let row" class="justify-content-between align-items-center">
                  <div>
                      <div class="truncate-text">{{ row.question?.title }}</div>
                      @if(row?.question?.type === 'template') {
                      @let doc = row?.question?.template;
                      <ng-container *ngTemplateOutlet="myDocTemplate; context: {
                                  $implicit: {
                                      documentKey: doc?.documentKey,
                                      documentName: doc?.documentName,
                                      documentType: doc?.documentType === 1 ? 2 : 1
                                  }
                              }"></ng-container>
                      }
                  </div>
                  <mat-icon class="view-icon" (click)="openDrawer.emit(row.question?.id)">visibility</mat-icon>
              </mat-cell>
          </ng-container>

          @for (column of header(); track column?._id) {
          <ng-container [matColumnDef]="column._id">
              <mat-header-cell *matHeaderCellDef>
                  @if( !viewProposal && !isProvider ) {
                  @if (!column?.profilePhoto) {
                    <span class="mr-2 alphabet" [ngClass]="getProfileClass($index)">
                        {{ column?.name?.charAt(0)?.toUpperCase() }}
                    </span>
                  } @else {
                    <span>
                        <img class="profile-image mr-2" src="{{ column?.profilePhoto }}" />
                    </span>
                  }
                  {{ column.name }}
              } @else {
                  {{ column.name }}
              }
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                  @let ans = row?.[column?._id] || {};
                  @if (column._id) {
                  @switch (ans?.type) {
                  @case ('input') {
                  <div>
                      <p [innerHTML]="ans?.value" class="truncate-text" #tinyMcValue></p>
                      @if(checkTruncation(tinyMcValue)) {
                      <span class="see-more" (click)="openDialog(row, ans)">{{'see more' | i18next}}</span>
                      }
                  </div>
                  }
                  @case('multipleChoice') {
                  @if(ans?.allowMultipleChoice) {
                  <p>{{ans.multipleChoiceAnswers?.join(', ')}}</p>
                  }@else {
                  <p class="truncate-text" #multiChoice>{{ans?.value}}</p>
                  @if(checkTruncation(multiChoice)) {
                      <span class="see-more" (click)="openDialog(row, ans)">{{'see more' | i18next}}</span>
                      }
                  }
                  }
                  @case('template') {
                  @let doc = ans?.document || {};
                  @if(doc?.documentKey) {
                  <ng-container *ngTemplateOutlet="myDocTemplate; context: { $implicit: doc }"></ng-container>
                  } @else {
                  <p>-</p>
                  }

                  }
                  @case('upload') {
                  @let doc = ans?.document || {};
                  @if(doc?.documentKey) {
                  <ng-container *ngTemplateOutlet="myDocTemplate; context: { $implicit: doc }"></ng-container>
                  } @else {
                  <p>-</p>
                  }
                  }
                  @default {
                  <p>-</p>
                  }
                  }
                  }
              </mat-cell>
          </ng-container>
          }

          <ng-container matColumnDef="groupHeader" sticky>
              <mat-cell *matCellDef="let group" [attr.colspan]="displayedColumns.length">
                  {{group.section}}
              </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns;" sticky></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="section"> </mat-row>

        </mat-table>
      </div>
      <div class="mt-5">
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page">
        </mat-paginator>
      </div>
    }
    @if((!header().length && !answer().length) ) {
      <div class="w-100 h-100 loader">
        <conpulse-web-no-content [data]="noData"></conpulse-web-no-content>
      </div>
    }
  }
</section>

<ng-template #myDocTemplate let-document>
    <div
        class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between m-10px">
        <div class="file-details overflow-hidden">
            @let upload = document.documentType === 2;
            <div class="d-flex">
                <div class="doc-image mr-2">
                    <img class="img-fit-cover"
                        [src]="utilityService.getAssetsPath(upload ? document.documentName : 'temp.url')" />
                </div>
                <p class="f-13 f-w-500 doc-name">{{
                    truncateDocumentName( upload ? document.documentName : document.documentKey) }}
                </p>
            </div>
        </div>
        <div class="file-actions ml-1">
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
                @if (upload) {
                  <button mat-menu-item (click)="downloadDocument(document)">
                      <mat-icon>download</mat-icon> {{'Download' | i18next}}
                  </button>
                }
                @if(!upload || utilityService.isPreviewAvailable(document?.documentName)) {
                  <button mat-menu-item (click)="viewDocument(document)">
                      <mat-icon>visibility</mat-icon> {{'View' | i18next}}
                  </button>
                }
            </mat-menu>
        </div>
    </div>
</ng-template>