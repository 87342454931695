import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConpulseModule } from 'libs/conpulse/src/index';
import { I18NextModule } from 'angular-i18next';
import { SupportContactComponent } from './support-contact.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [SupportContactComponent],
  imports: [CommonModule, FormsModule, I18NextModule, ConpulseModule, MatIconModule, MatDialogModule],
  exports: [SupportContactComponent],
})
export class SupportContactModule {}
