<div class="bg-white border-radius-6 mb-12 p-x-20 p-y-12"  *ngIf="isClient || (!isClient && !isSuperAdmin)">
  <div class="d-flex align-items-center justify-content-between"
    [ngClass]="{ 'h-36': isEmpty(accountDetails?.plan) && isSuperAdmin && isClient }">
    <h3 class="page-title font-Jakarta">{{ 'Account' | i18next }}</h3>
    <div class="d-flex align-items-center">
      <button mat-stroked-button color="primary" *ngIf="isSuperAdmin && !isClient && !isLoading"
        (click)="openClientAdminDrawer()">
        {{ 'Change Admin' | i18next }}
      </button>
    </div>
  </div>
</div>

 <!-- TODO: can be removed once the release/v1.65 Completed -->
<!-- 'p-x-20 p-y-12': isClient || (!isClient && !isSuperAdmin) -->
<div class="subscription-container bg-white border-radius-6"
  [ngClass]="{ client: isClient, firm: !isClient, 'super-admin': isSuperAdmin }">
   <!-- TODO: can be removed once the release/v1.65 Completed -->
  <!-- <ng-container *ngIf="isLoading; else planContainerRef">
    <div class="d-flex">
      <div class="flex-1" *ngFor="let j of [1]">
        <div class="mb-3" *ngFor="let i of [1, 2, 3]">
          <p class="f-13 f-w-600 txt-black l-h-16 skeleton-loader skeleton-title h-auto">{{ 'Type' }}</p>
          <p class="f-14 f-w-400 txt-hint l-h-20 skeleton-loader skeleton-title h-auto">{{ 'Subscription' }}</p>
        </div>
      </div>
    </div>
  </ng-container> -->
    <nav mat-tab-nav-bar class="nav-tab-group" [tabPanel]="tabPanel">
      <ng-container *ngFor="let tab of activeTabs">
        <a
          mat-tab-link
          [routerLink]="tab.url"
          routerLinkActive="active"
          #tabLink="routerLinkActive"
          [active]="tabLink.isActive"
        >
          <span>{{ tab.label | i18next }}</span>
        </a>
      </ng-container>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <router-outlet></router-outlet>
    <!-- TODO: can be removed once the release/v1.65 Completed -->
    <!-- <ng-container *ngIf="!isEmpty(accountDetails?.plan); else emptyPlanContainer">
      <div class="d-flex">
        <div class="flex-one border-radius-xlarge subscription-panel">
          <div class="d-flex align-items-center justify-content-between subscription-header p-x-20 py-3">
            <div>
              <p class="subscription-title mb-8">{{ 'Subscription Plan' | i18next }}</p>
              <p class="plan-active">{{ 'Active Plan' | i18next }}</p>
            </div>
            <div class="mr-3">
              <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                *ngIf="!isEmpty(accountDetails?.plan) && isSuperAdmin"
                [matMenuTriggerFor]="subscriptionMenu">more_vert</mat-icon>
              <mat-menu #subscriptionMenu="matMenu">
                <button mat-menu-item (click)="openAddEditDrawer(accountTypes.Plan)"><mat-icon>edit</mat-icon>{{ 'Edit
                  Plan' | i18next }}</button>
              </mat-menu>
            </div>
          </div>
          <div class="d-flex p-x-20 py-3">
            <div class="flex-1">
              <div class="mb-3" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.clientId?.clientName) || '-' }}</p>
              </div>
              <div class="mb-3" *ngIf="!isClient">
                <p class="p-title m-b-2">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.administrativeInfo?.adminContact.name) || '-' }}</p>
              </div>
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'Start Date' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.startDate; else emptyData">
                  {{ accountDetails?.plan?.startDate | date }}
                </p>
              </div>
              <div class="mb-3" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Client No' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.clientId?.clientNo || '-' }}</p>
              </div>
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'Period' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.timePeriod; else emptyData">
                  {{ accountDetails?.plan?.timePeriod | objectKey: periodOptions }}
                </p>
              </div>
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'Renewal Type' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.renewalType; else emptyData">
                  {{ accountDetails?.plan?.renewalType | objectKey: renewalOptions | i18next }}
                </p>
              </div>

            </div>
            <div class="flex-1">
              <div class="mb-3">
                <p class="p-title m-b-2">{{ 'End Date' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.endDate; else emptyData">{{ accountDetails?.plan?.endDate
                  | date }}</p>
              </div>
              <div class="mb-3" *ngIf="isClient; else consultingFirm">
                <p class="p-title m-b-2">{{ 'PO Number' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.plan?.poNumber || '-' }}</p>
              </div>
              <ng-template #consultingFirm>
                <div class="mb-3">
                  <p class="p-title m-b-2">{{ 'Process Fee' | i18next }}</p>
                  <p class="p-info">{{ accountDetails?.plan?.processFee || '-' }}%</p>
                </div>
              </ng-template>
              <div class="mb-3">
                <p class="p-title">{{ 'Type' | i18next }}</p>
                <p class="p-info">{{ accountDetails?.plan?.planType || 'Subscription' }}</p>
              </div>
              <div class="mb-3" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Nature' | i18next }}</p>
                <p class="p-info" *ngIf="accountDetails?.plan?.planNature; else emptyData">
                  {{ accountDetails?.plan?.planNature }}
                </p>
              </div>

              <div class="mb-1" *ngIf="!isClient">
                <p class="p-title m-b-2">{{'Marketplace Fee' | i18next}}</p>
                <p class="p-info">{{ accountDetails?.applicationFee  || '-' }}</p>
              </div>

              <div class="mb-1" *ngIf="isClient">
                <p class="p-title m-b-2">{{ 'Marketplace Status' | i18next }}</p>
                <div *ngIf="accountDetails?.isOptedForMarketPlace; else showNotSubscribed">
                  <span class="tag tag-approved">{{ 'Subscribed' | i18next }}</span>
                </div>
                <ng-template #showNotSubscribed>
                  <span class="tag tag-rejected" *ngIf="isSuperAdmin; else showSendBtn">{{ 'Not Subscribed' | i18next
                    }}</span>
                  <ng-template #showSendBtn>
                    <button mat-flat-button color="primary" class="small" (click)="notifySuperAdmin()">
                      Request Activation
                    </button>
                  </ng-template>
                </ng-template>
              </div>

            </div>
          </div>
        </div>
        <div class="flex-one"></div>
      </div>
      <div class="d-flex mt-3">
        <div class="flex-one border-radius-xlarge subscription-panel">
          <div class="p-x-20 py-3">
            <p class="subscription-title mb-8">{{ 'Onboarding Status' | i18next }}</p>
            <mat-form-field class="w-26 mb-0" subscriptSizing="dynamic"
              [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select required [(ngModel)]="onboardingStatus" ngModel name="onboardingStatus"
                id="onboardingStatusSelect" placeholder="Select Status" (selectionChange)="changeOnboardingStatus()"
                [disabled]="!isSuperAdmin">
                <mat-option *ngFor="let status of OnboardingStatusType" [value]="status.value"> {{ status.label
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex-one"></div>
      </div>
    </ng-container>
    <ng-template #emptyPlanContainer>
      <div class="align-items-center d-flex empty-container h-100 justify-content-center"
        *ngIf="isClient; else firmPlan">
        <div class="p-3 d-flex align-items-center justify-content-center flex-column">
          <img class="vector-image" [src]="notFoundImage" alt="no data image" />
          <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
          <p class="nodata-summary mw-400 m-t-12">{{ "Sorry there Isn't any record to display" | i18next }}</p>
          <button color="secondary" type="button" class="medium mt-2" mat-stroked-button [hidden]="!isSuperAdmin"
            (click)="openAddEditDrawer(accountTypes.Plan)">
            Add Plan
          </button>
        </div>
      </div>
      <ng-template #firmPlan>
        <div class="d-flex">
          <div class="flex-one border-radius-xlarge subscription-panel">
            <div class="d-flex align-items-center justify-content-between subscription-header p-x-20 py-3">
              <div>
                <p class="subscription-title mb-8">{{ 'Admin Name' | i18next }}</p>
                <p class="p-info">{{ (accountDetails?.administrativeInfo?.adminContact.name) || '-' }}</p>
              </div>
              <div class="mr-3">
                <button color="secondary" type="button" class="medium mt-2" mat-stroked-button [hidden]="!isSuperAdmin"
                  (click)="openAddEditDrawer(accountTypes.Plan)">
                  Add Plan
                </button>
              </div>
            </div>
          </div>
          <div class="flex-one"></div>
        </div>

        <div class="d-flex mt-3">
          <div class="flex-one border-radius-xlarge subscription-panel">
            <div class="p-x-20 py-3">
              <p class="subscription-title mb-8">{{ 'Onboarding Status' | i18next }}</p>
              <mat-form-field class="w-26 mb-0" subscriptSizing="dynamic"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
                <mat-select required [(ngModel)]="onboardingStatus" ngModel name="onboardingStatus"
                  id="onboardingStatusSelect" placeholder="Select Status" (selectionChange)="changeOnboardingStatus()"
                  [disabled]="!isSuperAdmin">
                  <mat-option *ngFor="let status of OnboardingStatusType" [value]="status.value"> {{ status.label
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-one"></div>
        </div>
      </ng-template>
    </ng-template> -->
</div>
<ng-template #emptyData>
  <p class="f-14 f-w-400 txt-hint l-h-20">-</p>
</ng-template>
