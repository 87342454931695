import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateUtilityFunctionService {
  constructor() {}

  defaultFormat = 'yyyy-MM-dd';

  updateStartDate(date) {
    return DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0 }).toJSDate();
  }

  updateEndDate(date) {
    return DateTime.fromJSDate(date).set({ hour: 23, minute: 59, second: 59 }).toJSDate();
  }

  convertToUtcFormat(date, format: string = this.defaultFormat) {
    return DateTime.fromISO(date, { zone: 'utc' }).toFormat(format);
  }

  getTimezoneOffset() {
    return DateTime.now().toFormat('Z');
  }

  formatDateAsString(date, format: string = this.defaultFormat) {
    return DateTime.fromISO(date).toFormat(format);
  }

  formatDate(date, format: string = this.defaultFormat) {
    return DateTime.fromISO(DateTime.fromISO(date).toFormat(format)).toISODate();
  }

  getStartOfMonth(date) {
    return DateTime.fromJSDate(date).startOf('month')
  }

  getEndOfMonth(date) {
    return DateTime.fromJSDate(date).endOf('month')
  }

  isBefore(date) {
    return DateTime.fromJSDate(date).toUTC() < DateTime.utc();
  }
}
