<div
    class="file-card-container bg-light-white p-x-20 p-y-12 border-radius-6 d-flex align-items-center justify-content-between">
    <div class="file-details flex-1 overflow-hidden">
        <p class="f-13 f-w-500 text-truncate">{{  document.type === docType.DOCUMENT  ?
            document.name : document.key }}
        </p>
    </div>
    <div class="file-actions ml-1">
        <ng-container
            *ngIf="((document.key?.length && document.isUploaded && isDownloadable(document?.key)) && ((document.key?.length && document.isUploaded && showDocViewBtn && isPreviewAvailable(document.key)) || showDelete && !disabled)) || (((document.key?.length && document.isUploaded && showDocViewBtn && isPreviewAvailable(document.key)) && (showDelete && !disabled))); else templateRef">
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
                [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
                <button mat-menu-item
                    *ngIf="document.key?.length && document.isUploaded && isDownloadable(document?.key)"
                    (click)="download.emit(document)">
                    <mat-icon>download</mat-icon> {{'Download' | i18next}}
                </button>
                <button mat-menu-item
                    *ngIf="document.key?.length && document.isUploaded && showDocViewBtn && isPreviewAvailable(document.key)"
                    (click)="view.emit(document)">
                    <mat-icon>visibility</mat-icon> {{'View' | i18next}}
                </button>
                <button mat-menu-item (click)="delete.emit(document)" *ngIf="showDelete && !disabled"
                    class="delete-action">
                    <mat-icon>delete</mat-icon> {{'Delete' | i18next}}
                </button>
            </mat-menu>
        </ng-container>
        <ng-template #templateRef>
            <button mat-stroked-button type="button" color="secondary" class="small mr-2"
                *ngIf="document.key?.length && document.isUploaded && isDownloadable(document?.key)"
                (click)="download.emit(document)">{{'Download' | i18next}}</button>
            <button mat-stroked-button type="button" color="primary" class="small mr-2"
                *ngIf="document.key?.length && document.isUploaded && showDocViewBtn && isPreviewAvailable(document.key)"
                (click)="viewDoc()">{{'View' | i18next}}</button>
            <button mat-stroked-button type="button" *ngIf="showDelete && !disabled" color="warn" class="small"
                (click)="delete.emit(document)">{{'Delete' | i18next}}</button>
        </ng-template>
    </div>
</div>
